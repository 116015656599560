// Loader.tsx
import React from 'react';
import { StyledBackdrop, StyledCircularProgress } from '../styles/StylesCommonView';

interface LoaderProps {
  showLoading: boolean;
}

const Loader: React.FC<LoaderProps> = ({ showLoading }) => {
  return (
    <StyledBackdrop data-testid="StyledBackdrop" open={showLoading}>
      <StyledCircularProgress data-testid="StyledCircularProgress"  />
    </StyledBackdrop>
  );
};

export default Loader;
