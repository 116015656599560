/* GlobalReducer.ts */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UsageLogData } from '../../api/apiParameterModels/UsageLogParameters';
import { Language } from '../../api/apiResultModels/KioskConfigurationResult';
import { KioskDetail } from '../../api/apiResultModels/KioskListResult';
import { APIDataType } from '../../enum';
import { KioskUIEntity } from '../../models/KioskUIEntity';
import { OrganizationUIEntity } from '../../models/OrganizationUIEntity';

export type GlobalState = {
    kiosks: KioskDetail[];
    orgs: OrganizationUIEntity[];
    currentOrg: OrganizationUIEntity | undefined;
    currentKiosk: KioskUIEntity | undefined;
    currentDate: string;
    currentTime: string;
    showLanguageScreen: boolean;
    selectedLanguage: Language | undefined;
    apiCallInProgress: boolean;
    usageLogs: UsageLogData[] | []
    usageLogLastSentDateTime: Date | undefined
}

const initialState: GlobalState = {
    kiosks: [],
    orgs: [],
    currentOrg: undefined,
    currentKiosk: undefined,
    currentDate: '',
    currentTime: '',
    showLanguageScreen: false,
    selectedLanguage: undefined,
    apiCallInProgress: false,
    usageLogLastSentDateTime: undefined,
    usageLogs: [],
}

export const globalSlice = createSlice({
    name: 'global',
    initialState: initialState,
    reducers: {
        setKiosks: (state, action: PayloadAction<KioskDetail[]>) => {
            state.kiosks = action.payload;
        },
        setOrgs: (state, action: PayloadAction<OrganizationUIEntity[]>) => {
            state.orgs = action.payload;
        },
        setCurrentOrg: (state, action: PayloadAction<OrganizationUIEntity | undefined>) => {
            state.currentOrg = action.payload;
        },
        setCurrentKiosk: (state, action: PayloadAction<KioskUIEntity | undefined>) => {
            state.currentKiosk = action.payload;
        },
        setCurrentDate: (state, action: PayloadAction<string>) => {
            state.currentDate = action.payload;
        },
        setCurrentTime: (state, action: PayloadAction<string>) => {
            state.currentTime = action.payload;
        },
        setShowLanguageScreen: (state, action: PayloadAction<boolean>) => {
            state.showLanguageScreen = action.payload;
        },
        setSelectedLanguage: (state, action: PayloadAction<Language | undefined>) => {
            state.selectedLanguage = action.payload;
        },
        setAPICallInProgress: (state, action: PayloadAction<boolean>) => {
            state.apiCallInProgress = action.payload;
        },
        resetGlobalState: () => {
            return initialState;
        },
        updateUsageLogs: (state, action: PayloadAction<string>) => {
            const newUsageLog: UsageLogData = {
                type: APIDataType.UsageLog,
                attributes: {
                    date: new Date().toISOString(),
                    message: action.payload
                }
            }
            if (state.usageLogs.length === 0) {
                state.usageLogs = [newUsageLog]
            } else {
                state.usageLogs = [...state.usageLogs, newUsageLog]
            }
        },
        resetUsageLogs: (state, action: PayloadAction<void>) => {
            state.usageLogs = []
        },
        setUsageLogLastSentDateTime: (state, action: PayloadAction<Date | undefined>) => {
            state.usageLogLastSentDateTime = action.payload;
        },
    }
})

export default globalSlice.reducer;