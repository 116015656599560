import { useTranslation } from "react-i18next";
import { ButtonText, Strings } from "../../../../../constants/StringConstant";
import { PatientInfoScreenAlertContainer, PatientInfoScreenAlertText1, PatientInfoScreenAlertText2, PatientInfoScreenAlertTextContainer, PatientInfoScreenCheckInButton } from "../../../styles/StylesCheckIn";

const PatientInfoAlertView: React.FC<{ handleContinueCheckInAction: () => void }> = ({ handleContinueCheckInAction }) => {
    const { t } = useTranslation();
    return (
        <PatientInfoScreenAlertContainer>
            <PatientInfoScreenAlertTextContainer>
                <PatientInfoScreenAlertText1>{t(Strings.CheckIn.PatientInfo.ItIsImportantThatWeHaveUpToDateInformationForYou)}</PatientInfoScreenAlertText1>
                <PatientInfoScreenAlertText2>{t(Strings.CheckIn.PatientInfo.PleaseLetTheReceptionTeamKnowThatWeNeedToUpdateYourRecords)}</PatientInfoScreenAlertText2>
            </PatientInfoScreenAlertTextContainer>
            <PatientInfoScreenCheckInButton onClick={handleContinueCheckInAction}>
                {t(ButtonText.ContinueCheckingIn)}
            </PatientInfoScreenCheckInButton>
        </PatientInfoScreenAlertContainer>
    )
}

export default PatientInfoAlertView