import React from 'react';
import { useTranslation } from 'react-i18next';
import { Strings } from '../../../../constants/StringConstant';
import { removeLastCharacter } from '../../../../helpers/CommonHelper';
import { isNonEmpty } from '../../../../helpers/StringHelper';
import { useAppSelector } from '../../../../redux/Store';
import KeyboardComponent from '../matchingScreen/dob/KeyboardComponent';
import { AdminPinContainerView, EmptyCircle, EnterPinText, FilledCircle, KeyboardContainer, PinView } from './StylesAdminScreen';

export interface AdminPinViewProps {
    onCompletePinEntry: (pin: string) => void
}

const AdminPinView: React.FC<AdminPinViewProps> = (props: AdminPinViewProps) => {
    const { t } = useTranslation();
    const [pin, setPin] = React.useState<string>('')
    const adminPassword = useAppSelector((state) => state.configSlice.kioskDetails?.adminPassword)
    const adminLoginPinSize = adminPassword?.length ?? 4

    function onNumberClick(number: number) {
        let updatedPin = pin
        if (pin.length < adminLoginPinSize) {
            updatedPin = `${pin}${number}`
            setPin(updatedPin)
        }
        if (updatedPin.length === adminLoginPinSize) {
            props.onCompletePinEntry(updatedPin)
        }
    }

    function onDeleteClick() {
        setPin(removeLastCharacter(pin))
    }

    return (
        <AdminPinContainerView>
            <EnterPinText data-testid="EnterPinText"> {t(Strings.Admin.EnterYourPinToLogIn)} </EnterPinText>
            <KeyboardContainer>
                <PinView>
                    {Array.from({ length: adminLoginPinSize }).map((number, index) => (
                        index < pin.length ?
                            (<FilledCircle data-testid={`FilledCircle${index}`} key={`${number}.${index}`} />)
                            :
                            (<EmptyCircle data-testid={`EmptyCircle${index}`} key={`${number}.${index}`} />)
                    ))}
                </PinView>
                <KeyboardComponent
                    onNumberClick={onNumberClick}
                    onDeleteClick={onDeleteClick}
                    onNextClick={() => { }}
                    enableDeleteButton={isNonEmpty(pin)}
                    enableNextButton={false}
                    nextText={''}
                    nextTextColor={'white'}
                />
            </KeyboardContainer>
        </AdminPinContainerView>
    )
}

export default AdminPinView
