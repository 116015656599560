import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import App from './App';
import { msalConfig } from "./AuthConfig";
import './index.css';
import i18n from './locales/i18n';
import initLaunchDarkly from "./manager/LaunchDarklyManager";
import { store } from './redux/Store';
import reportWebVitals from './reportWebVitals';

const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const renderApp = async () => {
  const LDProvider = await initLaunchDarkly();
  root.render(
    <LDProvider>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </I18nextProvider>
      </Provider>
    </LDProvider>
  );
};

renderApp();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

