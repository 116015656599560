/* BookAppointmentConfirmation.tsx */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Strings } from '../../../../constants/StringConstant';
import { DateFormat, formatDateString } from '../../../../helpers/DateTimeHelper';
import { isEmpty } from '../../../../helpers/StringHelper';
import { store } from '../../../../redux/Store';
import { SlotUIEntity } from '../../../../uiEntities/SlotUIEntity';
import { AppConfig } from '../../../../utils/AppConfig';
import TextKeyboardComponent from '../../commonViews/TextKeyboardComponent';
import { BookAppointmentButton, BookAppointmentConfirmationContainer, BookAppointmentConfirmationInnerContainer, DividerInConfirmation, FooterButtonView, QuestionnaireKeyboardContainerView, QuestionnaireTextView, ReasonContainer, ReasonFooterText, ReasonHeaderText, SessionHolderNameTextInConfirmation, SlotContentView, SlotDateTextInConfirmation, SlotInfoView, SlotLocationTextInConfirmation, SlotLocationTextInConfirmationContainer, SlotTimeTextInConfirmation, SlotTimeTypeView, SlotTypeTextInConfirmation, SlotView, TitleText } from '../../styles/StylesBookAppointment';

interface BookAppointmentConfirmationProps {
    handleConfirmBookAppointmentAction: (slot: SlotUIEntity, bookingReason: string) => void
    selectedSlot: SlotUIEntity
}

const BookAppointmentConfirmation: React.FC<BookAppointmentConfirmationProps> = (props: BookAppointmentConfirmationProps) => {
    const { t } = useTranslation();
    const appointmentReason = store.getState().configSlice.kioskDetails?.appointmentReason
    const isShowReasonForAppointment: boolean = appointmentReason !== undefined
    const isMandatory_ReasonForAppointment: boolean = appointmentReason ?? false

    const formattedDate = props.selectedSlot.date ? formatDateString(props.selectedSlot.date, DateFormat.DDMMYY_Slash, DateFormat.dddd_Do_MMMM_YYYY) : ''
    const startTime = props.selectedSlot.startDateTime ?? ''
    const endTime = props.selectedSlot.endDateTime ?? ''
    const [showKeyboard, setShowKeyboard] = React.useState<boolean>(isShowReasonForAppointment)

    const [reason, setReason] = React.useState<string>('')

    function onKeyClick(text: string) {
        if (reason.length < AppConfig.defaultValues.BookingReasonMaxAllowedCharacter) {
            setReason(`${reason}${text}`)
        }
    }

    function onDeleteClick() {
        setReason(reason.slice(0, -1))
    }

    function isShiftOn() {
        return isEmpty(reason)
    }

    return (
        <BookAppointmentConfirmationContainer data-testid="BookAppointmentConfirmationContainer">
            <BookAppointmentConfirmationInnerContainer data-testid="BookAppointmentConfirmationInnerContainer">
                <TitleText data-testid="TitleText">{t(Strings.Home.BookAppointment)}</TitleText>
                <SlotContentView data-testid="SlotContentView">
                    <SlotView data-testid="SlotView">
                        <SlotInfoView data-testid="SlotInfoView">
                            <SlotDateTextInConfirmation data-testid="SlotDateTextInConfirmation">{formattedDate}</SlotDateTextInConfirmation>
                            <SlotTimeTypeView data-testid="SlotTimeTypeView">
                                <SlotTimeTextInConfirmation data-testid="SlotTimeTextInConfirmation">{`${startTime} - ${endTime}`}</SlotTimeTextInConfirmation>
                                <SlotTypeTextInConfirmation data-testid="SlotTypeDescriptionInConfirmation">{`(${props.selectedSlot.slotTypeDescription})`}</SlotTypeTextInConfirmation>
                            </SlotTimeTypeView>
                            <SessionHolderNameTextInConfirmation data-testid="SessionHolderNameTextInConfirmation">{`${props.selectedSlot.sessionHolderName}`}</SessionHolderNameTextInConfirmation>
                        </SlotInfoView>
                        <DividerInConfirmation />
                        <SlotLocationTextInConfirmationContainer data-testid="SlotLocationTextInConfirmationContainer">
                            <SlotLocationTextInConfirmation data-testid="SlotLocationTextInConfirmation">{`${props.selectedSlot.locationDisplayName}`}</SlotLocationTextInConfirmation>
                        </SlotLocationTextInConfirmationContainer>
                    </SlotView>
                    {isShowReasonForAppointment ?
                        <ReasonContainer data-testid="ReasonContainer">
                            <ReasonHeaderText data-testid="ReasonHeaderText">
                                {`${t(Strings.BookAppointment.ReasonForAppointment)} (${isMandatory_ReasonForAppointment ? t(Strings.mandatory) : t(Strings.optional)})`}
                            </ReasonHeaderText>
                            <QuestionnaireTextView data-testid="QuestionnaireTextView" sx={{ height: showKeyboard ? 'auto' : '5vh', }} onClick={() => {
                                setShowKeyboard(true)
                            }}>
                                {reason}
                            </QuestionnaireTextView>
                            <ReasonFooterText data-testid="ReasonFooterText">{`${reason.length} / ${AppConfig.defaultValues.BookingReasonMaxAllowedCharacter}`}</ReasonFooterText>
                        </ReasonContainer>
                        : null
                    }
                </SlotContentView>
            </BookAppointmentConfirmationInnerContainer>
            {showKeyboard ?
                <QuestionnaireKeyboardContainerView data-testid="QuestionnaireKeyboardContainerView">
                    <TextKeyboardComponent
                        onKeyClick={onKeyClick}
                        onDeleteClick={onDeleteClick}
                        onCloseClick={() => setShowKeyboard(false)}
                        isShiftOn={isShiftOn()}
                    />
                </QuestionnaireKeyboardContainerView>
                : false
            }
            {!showKeyboard ?
                <FooterButtonView data-testid="BookAppointmentFooterButtonView">
                    <BookAppointmentButton data-testid="BookAppointmentFooterButton" onClick={() => props.handleConfirmBookAppointmentAction(props.selectedSlot, reason)}>
                        {Strings.Home.BookAppointment}
                    </BookAppointmentButton>
                </FooterButtonView>
                : null
            }
        </BookAppointmentConfirmationContainer>
    )
}


export default BookAppointmentConfirmation;