import { useTranslation } from "react-i18next"
import { Module } from "../../../../api/apiResultModels/KioskConfigurationResult"
import { ModuleName } from "../../../../constants/StringConstant"
import { useAppSelector } from "../../../../redux/Store"
import { BookAppointmentButton, CheckInButton, HomeScreenOptionsView, SiteMapQuestionnairesButton, SiteMapQuestionnairesView } from "../../styles/StylesHome"

interface HomeScreenProps {
    handleCheckInAction: () => void
    handleBookAppointmentAction: () => void
    siteMapAction: () => void
    questionnairesAction: () => void
    modules: Module[]
    portrait: boolean
    mobile: boolean
}

const HomeScreenOptions: React.FC<HomeScreenProps> = (props: HomeScreenProps) => {
    const { t } = useTranslation();
    const arrivalModule = props.modules.find(module => module.moduleId === 1)
    const questionnairesModule = props.modules.find(module => module.moduleId === 2)
    const siteMapModule = props.modules.find(module => module.moduleId === 3)
    const makeAppointmentModule = props.modules.find(module => module.moduleId === 4)
    const footerButtonFlex = siteMapModule && questionnairesModule ? 1 : 0.5
    const anonymousSurveysFromConfig = useAppSelector((state) => state.configSlice.anonymousSurveysFromConfig) ?? []
    const slotTypes = useAppSelector((state) => state.configSlice.slotTypes) ?? []

    return (
        <HomeScreenOptionsView sx={{ width: props.mobile || props.portrait ? '80%' : '50%' }} data-testid="HomeScreenOptions">

            {arrivalModule ?
                <CheckInButton data-testid="CheckInButton" onClick={props.handleCheckInAction}>
                    {t(ModuleName.CheckIn)}
                </CheckInButton>
                : null
            }

            {makeAppointmentModule && slotTypes.length > 0 ?
                <BookAppointmentButton data-testid="BookAppointmentButton"
                    onClick={props.handleBookAppointmentAction}
                >
                    {t(ModuleName.BookAppointment)}
                </BookAppointmentButton>
                : null
            }

            {siteMapModule || questionnairesModule ?
                <SiteMapQuestionnairesView data-testid="SiteMapQuestionnairesView">
                    {siteMapModule ?
                        <SiteMapQuestionnairesButton
                            data-testid="SiteMapButton"
                            onClick={props.siteMapAction}
                            style={{ flex: footerButtonFlex }}
                        >
                            {t(ModuleName.SiteMap)}
                        </SiteMapQuestionnairesButton>
                        : null
                    }

                    {questionnairesModule && anonymousSurveysFromConfig.length > 0 ?
                        <SiteMapQuestionnairesButton
                            data-testid="QuestionnairesButton"
                            onClick={props.questionnairesAction}
                            style={{ flex: footerButtonFlex }}
                        >
                            {t(ModuleName.Questionnaires)}
                        </SiteMapQuestionnairesButton>
                        : null
                    }
                </SiteMapQuestionnairesView>
                : null
            }

        </HomeScreenOptionsView>
    );
};

export default HomeScreenOptions