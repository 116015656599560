import { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { LocalStorageKey } from "../constants/StringConstant";
import i18n, { languages } from "../locales/i18n";
import { KioskUIEntity } from "../models/KioskUIEntity";
import { OrganizationUIEntity } from "../models/OrganizationUIEntity";
import { RouterName } from "../navigation/RouterName";
import { resetConfigState } from "../redux/actions/ConfigAction";
import { resetGlobalState, setSelectedLanguage } from "../redux/actions/GlobalAction";
import { resetTokenState } from "../redux/actions/TokenAction";
import { store } from "../redux/Store";
import { AppConfig } from "../utils/AppConfig";
import { HomeViewModel } from "../viewModels/HomeViewModel";
import { isNonEmpty } from "./StringHelper";

export const addSpacesBetweenCharacters = (text: string) => {
    return text.split('').join(' ');
}

export const padDigitsInPrefix = (numStr: string, digits: number): string => {
    return numStr.padStart(digits, '0');
};

export const padDigitsInSuffix = (numStr: string, digits: number): string => {
    if (numStr.length >= digits) {
        return numStr
    }
    const zerosNeeded = digits - numStr.length;
    return numStr + '0'.repeat(zerosNeeded);
};

export const getDaySuffix = (day: number): string => {
    if (day % 10 === 1 && day !== 11) return 'st';
    if (day % 10 === 2 && day !== 12) return 'nd';
    if (day % 10 === 3 && day !== 13) return 'rd';
    return 'th';
};

export const getMonth = (month: string): string => {
    const monthInt = parseInt(month)
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return (isNaN(monthInt) || monthInt < 1 || monthInt > 12) ? '' : months[monthInt - 1]
}

export const convertNumberToWord = (number: number) => {
    const units = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
    const teens = ["eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
    const tens = ["", "ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
    if (number === 0) return "zero";
    if (number < 10) return units[number];
    if (number > 10 && number < 20) return teens[number - 11];
    if (number >= 10 && number < 100) {
        const ten = Math.floor(number / 10);
        const unit = number % 10;
        return tens[ten] + (unit ? "-" + units[unit] : "");
    }
    return "";
};

export const removeLastCharacter = (srcString: string): string => {
    if (srcString.length === 0) {
        return ''
    } else {
        return srcString.slice(0, -1)
    }
}

export const maskInfo = (email: string, position: 'front' | 'back' = 'back', visibleChars: number = 3): string => {
    const [localPart, domain] = email.split('@');
    if (domain) {
        if (localPart.length <= 2) {
            return localPart; // Not enough characters to mask
        }
        // Mask all characters except the first and last
        return localPart[0] + '*'.repeat(localPart.length - 2) + localPart[localPart.length - 1] + `@${domain}`
    } else {
        let localPartMasked: string;
        if (position === 'front') {
            localPartMasked = `${localPart.substring(0, visibleChars)}${'*'.repeat(localPart.length - visibleChars)}`;
        } else {
            localPartMasked = `${'*'.repeat(localPart.length - visibleChars)}${localPart.substring(localPart.length - visibleChars)}`;
        }
        return localPartMasked
    }
};

export function getLanguageText(languageName?: string): string {
    if (languageName && isNonEmpty(languageName)) {
        return languages[languageName]
    } else {
        return ""
    }
}

export function resetToDefaultLanguage(dispatch: Dispatch<UnknownAction>) {
    const languages = HomeViewModel().getLanguages()
    if (languages && languages.length > 0) {
        const sortedLanguagesByOrder = [...languages].sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        const defaultLanguage = sortedLanguagesByOrder[0]
        dispatch(setSelectedLanguage(defaultLanguage))
        i18n.changeLanguage(defaultLanguage.languageCode);
    }
}

export function compareDateComponents(componentInput: string, componentType: 'day' | 'month' | 'year', inputDate?: string,): boolean {
    if (!inputDate) {
        return false;
    }
    const normalizeDateString = (dateStr: string): string[] => {
        const normalized = dateStr.replace(/[-.]/g, '/');
        return normalized.split('/');
    };
    const [day, month, year] = normalizeDateString(inputDate);
    switch (componentType) {
        case 'day':
            return day ? day === componentInput : false;
        case 'month':
            return month ? month === componentInput : false;
        case 'year':
            return year ? year === componentInput : false;
    }
};

export function handleHomeClick(dispatch: Dispatch<UnknownAction>, navigate: NavigateFunction) {
    resetToDefaultLanguage(dispatch);
    navigate(RouterName.OrgList);
};

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const getAllOrgs = (): OrganizationUIEntity[] | [] => {
    const allOrgs = localStorage.getItem(LocalStorageKey.AllOrgs) ?? '';
    try {
        const selectedOrgEntities: OrganizationUIEntity[] = JSON.parse(allOrgs)
        return selectedOrgEntities
    } catch (error) {
        return []
    }
}

export const getSelectedOrg = (): OrganizationUIEntity | undefined => {
    const selectedOrg = localStorage.getItem(LocalStorageKey.SelectedOrg) ?? '';
    try {
        const selectedOrgEntity: OrganizationUIEntity = JSON.parse(selectedOrg)
        return selectedOrgEntity
    } catch (error) {
        return undefined
    }
}

export const getSelectedKiosk = (): KioskUIEntity | undefined => {
    const selectedKiosk = localStorage.getItem(LocalStorageKey.SelectedKiosk) ?? '';
    try {
        const selectedKioskEntity: KioskUIEntity = JSON.parse(selectedKiosk)
        return selectedKioskEntity
    } catch (error) {
        return undefined
    }
}

export const isKioskSelected = (): boolean => {
    const selectedKiosk = getSelectedKiosk()
    return isNonEmpty(selectedKiosk?.id ?? '')
}

export const calculateAge = (birthDateString: string) => {
    const [day, month, year] = birthDateString.split('/').map(Number);

    const birthDate = new Date(year, month - 1, day); // month is 0-indexed
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();

    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
};

export const splitStringToArray = (splitString: string, stringToSplit?: string) => {
    if (stringToSplit && isNonEmpty(stringToSplit)) {
        return stringToSplit.split(splitString).map(matchTitle => matchTitle.trim())
    } else {
        return []
    }
}

export const getScreenTimeOutInMilliSeconds = (timer?: number) => {
    return (timer ?? AppConfig.defaultValues.ScreenTimeOut) * 1000
}

export function clearAllAndRefresh(dispatch: Dispatch<UnknownAction>) {
    localStorage.clear()
    dispatch(resetConfigState())
    dispatch(resetGlobalState())
    dispatch(resetTokenState())
    window.electron.relaunchApp()
}

export function errorToString(error: unknown): string {
    if (error instanceof Error) {
        return error.toString(); // or error.message
    }
    return String(error);
}

export const sendUsageLogs = (): boolean => {
    const useLogs = store.getState().globalSlice.usageLogs
    if (useLogs.length === 0) {
        return false
    }
    const usageLogLastSentDateTime = store.getState().globalSlice.usageLogLastSentDateTime
    if (usageLogLastSentDateTime) {
        const currentTime = new Date();
        const timeDifferenceInMilliseconds = currentTime.getTime() - usageLogLastSentDateTime.getTime();
        const oneHourInMilliseconds = 60 * 60 * 1000;
        
        return useLogs.length >= 10 || timeDifferenceInMilliseconds > oneHourInMilliseconds
    } else {
        return useLogs.length > 0
    }
}