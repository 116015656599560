export const RouterName = {
    SplashScreen: '/',
    KioskList: '/KioskList',
    OrgList: '/OrgList',
    Home: '/Home',
    CheckIn: '/CheckIn',
    BookAppointment: '/BookAppointment',
    SiteMap: '/SiteMap',
    Admin: '/Admin',
    Survey:'/Survey',
} as const;