import React from "react"
import { useTranslation } from "react-i18next"
import { ButtonText } from "../../../../constants/StringConstant"
import { isEmpty } from "../../../../helpers/StringHelper"
import { store } from "../../../../redux/Store"
import { AppConfig } from "../../../../utils/AppConfig"
import NumericKeyboardComponent from "../../commonViews/NumericKeyboardComponent"
import TextKeyboardComponent from "../../commonViews/TextKeyboardComponent"
import { QuestionContinueButton, QuestionnaireKeyboardContainerView, QuestionnaireScreenTextInnerView, QuestionnaireScreenTextView, QuestionnaireTextView } from '../../styles/StylesQuestionnaire'

export interface QuestionnaireTextScreenProps {
    text: string
    keyboardType: 'text' | 'numeric'
    continueAction: (text: string) => void
    skipQuestionAction: () => void
    charLimit: number
}

const QuestionnaireTextScreen: React.FC<QuestionnaireTextScreenProps> = (props: QuestionnaireTextScreenProps) => {
    const [answerText, setAnswerText] = React.useState<string>(props.text)
    const [showKeyboard, setShowKeyboard] = React.useState<boolean>(true)
    const { t } = useTranslation();
    const skipSurveyQuestion = store.getState().configSlice.kioskDetails?.skipSurveyQuestion

    function onKeyClick(number: string) {
        if(answerText.length < (props.charLimit === 0 ? AppConfig.defaultValues.MaxAllowedCharacter : props.charLimit)) {
            setAnswerText(`${answerText}${number}`)
        }
    }

    function onDeleteClick() {
        setAnswerText(answerText.slice(0, -1))
    }

    return (
        <QuestionnaireScreenTextView>
            <QuestionnaireScreenTextInnerView>
                <QuestionnaireTextView onClick={() => setShowKeyboard(true)}>
                    {answerText}
                </QuestionnaireTextView>
                <div style={{display: 'flex', flexDirection: 'row', gap: 20}}>
                    <QuestionContinueButton disabled={isEmpty(answerText)} onClick={() => props.continueAction(answerText)}>{t(ButtonText.Continue)}</QuestionContinueButton>
                    {skipSurveyQuestion ? <QuestionContinueButton onClick={() => props.skipQuestionAction()}>{t(ButtonText.SkipQuestion)}</QuestionContinueButton> : null}
                </div>
            </QuestionnaireScreenTextInnerView>
            {showKeyboard && props.keyboardType === 'numeric'  ?
                <QuestionnaireKeyboardContainerView>
                    <NumericKeyboardComponent
                        onKeyClick={onKeyClick}
                        onDeleteClick={onDeleteClick}
                        onCloseClick={() => setShowKeyboard(false)}
                    />
                </QuestionnaireKeyboardContainerView>
                : false
            }
            {showKeyboard && props.keyboardType === 'text' ?
                <QuestionnaireKeyboardContainerView>
                    <TextKeyboardComponent
                        onKeyClick={onKeyClick}
                        onDeleteClick={onDeleteClick}
                        onCloseClick={() => setShowKeyboard(false)}
                        isShiftOn={isEmpty(answerText)} 
                    />
                </QuestionnaireKeyboardContainerView>
                : false
            }
        </QuestionnaireScreenTextView>
    )
}

export default QuestionnaireTextScreen