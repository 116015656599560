// APIRetryUtil.ts

export async function withRetry<T>(fn: () => Promise<T>, retries: number = 3, delayMs: number = 1000): Promise<T> {
  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  for (let attempt = 0; attempt < retries; attempt++) {
    try {
      return await fn(); // Return result if API is successful
    } catch (error) {
      if (attempt < retries - 1) {
        await delay(delayMs);
      } else {
        throw error;
      }
    }
  }
  throw new Error('Unable to complete request after retries');
}
