import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonText, ErrorMessage, LocalStorageKey, Strings } from "../../../../constants/StringConstant";
import { clearAllAndRefresh, getAllOrgs } from "../../../../helpers/CommonHelper";
import { OrganizationUIEntity } from "../../../../models/OrganizationUIEntity";
import { RouterName } from "../../../../navigation/RouterName";
import { setCurrentOrg } from "../../../../redux/actions/GlobalAction";
import { store, useAppDispatch } from "../../../../redux/Store";
import { LoginViewModel } from "../../../../viewModels/LoginViewModel";
import APIFailScreen from "../../commonViews/APIFailScreen";
import HeaderQuestion from "../../commonViews/HeaderQuestion";
import MessageBanner from "../../commonViews/MessageBanner";
import TopBar from "../../commonViews/TopBar";
import { HeaderQuestionContainer } from "../../styles/StylesMatchingScreen";
import OrganisationListGridView from "./OrganisationListGridView";
import { OrganisationListContainerView, OrganisationView } from "./StylesOrganisation";

export interface OrgCredential {
    ServiceName: string,
    ClientId: string,
    ClientSecret: string,
}

export interface AlertContent {
    title?: string
    message?: string
}

const OrganisationListScreen: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [organisations, setOrganisations] = React.useState<OrganizationUIEntity[]>([])
    const [alertContent, setAlertContent] = React.useState<AlertContent | undefined>(undefined)
    const [showAlert, setShowAlert] = React.useState<boolean>(false)

    const handleOrgSelection = React.useCallback(async (selectedOrg: OrganizationUIEntity) => {
        dispatch(setCurrentOrg(selectedOrg))
        const clientSecretAvailable = await LoginViewModel().clientSecretAvailable(selectedOrg)
        if (clientSecretAvailable) {
            localStorage.setItem(LocalStorageKey.SelectedOrg, JSON.stringify(selectedOrg));
            setTimeout(() => {
                navigate(RouterName.Home);
            }, 500);
        } else {
            const kioskName = store.getState().globalSlice.currentKiosk?.name
            const kioskID = store.getState().globalSlice.currentKiosk?.id
            const orgName = selectedOrg.name
            const orgID = selectedOrg.id
            const information = `Kiosk Name: ${kioskName}\nKiosk Id: ${kioskID}\nOrganisation Name: ${orgName}\nOrganisation Id: ${orgID}\n\n${ErrorMessage.KioskRegistrationFailed}`
            setAlertContent({ title: 'Failed', message: information })
            setShowAlert(true)
        }
    }, [dispatch, navigate]);

    React.useEffect(() => {
        const allOrgUIEntities = getAllOrgs()
        if (allOrgUIEntities.length === 0) {
            navigate(RouterName.SplashScreen)
        } else if (allOrgUIEntities.length === 1) {
            handleOrgSelection(allOrgUIEntities[0]);
        } else {
            setOrganisations(allOrgUIEntities);
        }
    }, [handleOrgSelection, navigate]);

    return (
        <OrganisationListContainerView>
            <TopBar
                isShowHomeButton={false}
                isShowBackOption={false}
                isShowLanguageOption={false}
                handleBackClick={() => { }}
                timeOutMessage={''}
            />
            {!showAlert ?
                <OrganisationView data-testid="OrganisationView">
                    {organisations.length > 0 ? <MessageBanner message={Strings.Home.Welcome} /> : null}
                    <HeaderQuestionContainer>
                        <HeaderQuestion data-testid="HeaderQuestion" headerText={organisations.length > 0 ? Strings.SelectOrganisation : ''} subHeaderText={''} />
                    </HeaderQuestionContainer>
                    <OrganisationListGridView data-testid="OrganisationListGridView" organisations={organisations} handleOrgSelection={handleOrgSelection} />
                </OrganisationView>
                : null
            }
            {showAlert && alertContent ?
                <APIFailScreen
                    title={alertContent?.title ?? ''}
                    information={alertContent?.message ?? ""}
                    closeAction={() => {
                        setAlertContent(undefined)
                        setShowAlert(false)
                    }}
                    buttonText={ButtonText.Ok}
                    tryAgainAction={() => clearAllAndRefresh(dispatch)}
                    isShowCloseAction={false}
                />
                : null
            }
        </OrganisationListContainerView>
    )
}

export default OrganisationListScreen
