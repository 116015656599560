import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonText, Strings } from '../../../constants/StringConstant';
import { PleaseReportToReceptionText, SomethingWentWrongScreenCloseButton, SomethingWentWrongScreenContainer, SomethingWentWrongScreenInfoContainer, SorryLooksLikeSomethingWentWrongText, SorryUnableToProcessYourRequestText } from '../styles/StylesCommonView';

interface SomethingWentWrongScreenProps {
    handleCloseAction: () => void;
}

const SomethingWentWrongScreen: React.FC<SomethingWentWrongScreenProps> = (props) => {
    const { t } = useTranslation();

    return (
        <SomethingWentWrongScreenContainer data-testid="SomethingWentWrongScreenContainer">
            <SomethingWentWrongScreenInfoContainer data-testid="SomethingWentWrongScreenInfoContainer">
                <SorryLooksLikeSomethingWentWrongText data-testid="SorryLooksLikeSomethingWentWrongText">
                    {t(Strings.CheckIn.SorryLooksLikeSomethingWentWrong)}
                </SorryLooksLikeSomethingWentWrongText>
                <SorryUnableToProcessYourRequestText data-testid='SorryUnableToProcessYourRequestText'>
                    {t(Strings.CheckIn.SorryUnableToProcessYourRequest)}
                </SorryUnableToProcessYourRequestText>
                <PleaseReportToReceptionText data-testid='PleaseReportToReceptionText'>
                    {t(Strings.CheckIn.PleaseReportToReception)}
                </PleaseReportToReceptionText>
            </SomethingWentWrongScreenInfoContainer>
            <SomethingWentWrongScreenCloseButton data-testid='SomethingWentWrongScreenCloseButton' onClick={props.handleCloseAction}>
                {t(ButtonText.Close)}
            </SomethingWentWrongScreenCloseButton>
        </SomethingWentWrongScreenContainer>
    );
};

export default SomethingWentWrongScreen