export enum DateType {
    DD = 'DD',
    MM = 'MM',
    YYYY = 'YYYY'
}

export enum CheckInScreenCode {
    Date = "DateOfBirth",
    Day = 'P_DAY',
    Month = 'P_MONTH',
    Year = 'P_DOB_YEAR',
    Gender = 'Gender',
    SurName = 'P_SURNAME',
}

export enum ModuleEnum {
    Arrival = "Arrival",
    MakeAppointment = 'Make Appointment',
    SiteMap = 'SiteMap',
    Questionnaires = 'Survey',
}

export enum MatchTitle {
    Date = "Full Date of birth",
    Date_1 = "Full Date of Birth",
    SurName = 'First letter of surname',
    SurName_1 = 'First Letter of Surname',
    Day = 'Day of birth',
    Month = 'Month of birth',
    Year = 'Year of birth',
    Gender = 'Gender',
    PostalCode = 'Postal Code'
}

export enum SlotStatus {
    SlotAvailable = 'Slot Available',
}

export enum PatientDemographicDetail {
    EmailAddress = 'Email Address',
    MobileNumber = 'Mobile number',
    Name = 'Name',
    Postcode = 'Postcode',
    HomeTelephoneNumber = 'Home telephone number',
}

export enum AdminActionType {
    CloseSoftware = 'Close Software',
    ChangeKiosk = 'Change Kiosk',
    RebootSystem = 'Reboot system',
    ShutdownSystem = 'Shut down system',
}

export enum APIDataType {
    NonAnonymousSurveyFrequency = 'ern:aax:nonAnonymousSurveyFrequency',
    AnonymousSurvey = 'ern:aax:anonymousSurvey',
    NonAnonymousSurvey = 'ern:aax:nonAnonymousSurvey',
    UsageLog = "ern:aax:usageLog",
}