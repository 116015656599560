/* BookAppointmentSlotSelection.tsx */
import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { AppointmentSession, Holder } from "../../../../../api/apiResultModels/AppointmentSessionsResult";
import { Strings } from "../../../../../constants/StringConstant";
import { DateFormat, formatDate } from "../../../../../helpers/DateTimeHelper";
import { store } from "../../../../../redux/Store";
import { SlotUIEntity } from "../../../../../uiEntities/SlotUIEntity";
import { BookAppointmentViewModel, CalendarComponent } from "../../../../../viewModels/BookAppointmentViewModel";
import { BookAppointmentMainContainer, BookAppointmentSlotListView, BookAppointmentSlotSelectionContainer, BookAppointmentSlotSelectionSelectedDateText, BookAppointmentSlotsMainView, BookAppointmentTopContainer, BookAppointmentTopDivider, NoAppointmentText } from "../../../styles/StylesBookAppointment";
import DateStripComponent from "../dateStripComponent/DateStripComponent";
import SessionHolderNameListView from "./SessionHolderNameListView";
import SlotListView from "./SlotListView";

interface BookAppointmentSlotSelectionProps {
    selectedDate: Date
    sessionsForTheSelectedDate: AppointmentSession[]
    handleDateSelection: (selectedDate: Date) => void
    weekWiseCalendarComponents: (CalendarComponent | undefined)[]
    handleCalendarIconSelection: () => void
    handleChangeInWeekSelection:(selectedWeekDate: Date) => void
    handleSlotSelection: (selectedSlot: SlotUIEntity) => void
}

const BookAppointmentSlotSelection: React.FC<BookAppointmentSlotSelectionProps> = (props: BookAppointmentSlotSelectionProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedSessionHolder, setSelectedSessionHolder] = React.useState<Holder | undefined>(undefined)
    const [sessionHolders, setSessionHolders] = React.useState<Holder[]>([])
    const [sessions, setSessions] = React.useState<AppointmentSession[]>([])
    const formattedSelectedDate = formatDate(props.selectedDate, DateFormat.ddd_DD_MMMM_YYYY)
    const { t } = useTranslation();

    React.useEffect(() => {
        const filterSessionWithAvailableSlots: AppointmentSession[] = BookAppointmentViewModel().filterSessionWithAvailableSlots(props.sessionsForTheSelectedDate);
        let holders = filterSessionWithAvailableSlots.flatMap(session => session.holderList?.holder ?? [])
        if(holders.length === 0) {
            return
        }
        const availableSessionHolders = store.getState().configSlice.sessionHolders ?? []
        if(availableSessionHolders.length > 0) {
            const sessionHolderIdsToFilter = availableSessionHolders.map(sessionHolder => sessionHolder.sessionHolderId);
            const filteredHolders = holders.filter(holder => sessionHolderIdsToFilter.includes(holder.dbid))
            holders = filteredHolders
        }
        const uniqueHolders = holders.filter((item, index, self) =>
            index === self.findIndex((t) => t.dbid === item.dbid)
        );
        setSessionHolders(uniqueHolders)
        if (selectedSessionHolder === undefined && holders.length > 0) {
            setSelectedSessionHolder(holders[0])
        }
        const filteredSessions = filterSessionWithAvailableSlots.filter(session => session.holderList?.holder?.some(holder => holder.dbid === selectedSessionHolder?.dbid))
        const sortedSessions = BookAppointmentViewModel().sortSessionOnStartDateTime(filteredSessions)
        setSessions(sortedSessions)

    }, [props.sessionsForTheSelectedDate, selectedSessionHolder])

    return (
        <BookAppointmentSlotSelectionContainer>
            <BookAppointmentTopContainer>
                <DateStripComponent 
                    weekWiseSessionsByDate={props.weekWiseCalendarComponents} 
                    selectedDate={props.selectedDate}
                    handleChangeInWeekSelection={props.handleChangeInWeekSelection}
                    handleDateSelection={props.handleDateSelection}
                    handleCalendarIconSelection={props.handleCalendarIconSelection} 
                />
            </BookAppointmentTopContainer>
            <BookAppointmentTopDivider />
            {sessions.length > 0 ?
                <BookAppointmentMainContainer isMobile={isMobile}>
                    <SessionHolderNameListView
                        holders={sessionHolders}
                        selectedSessionHolder={selectedSessionHolder}
                        handleSessionHolderSelection={(sessionHolder) => {
                            setSelectedSessionHolder(sessionHolder)
                        }}
                    />
                    <BookAppointmentSlotsMainView>
                        <BookAppointmentSlotSelectionSelectedDateText>{formattedSelectedDate}</BookAppointmentSlotSelectionSelectedDateText>
                        <BookAppointmentSlotListView>
                            {
                                sessions.map((session) => {
                                    return (
                                        <SlotListView
                                            key={`${session.dbid}`}
                                            slots={session.slots ?? []}
                                            handleSlotSelection={(slot) => {
                                                const slotUIEntity = BookAppointmentViewModel().mapToSlotUIEntities(session, [slot])[0]
                                                props.handleSlotSelection(slotUIEntity)
                                            }}
                                            siteName={session.site?.name ?? ''}
                                        />
                                    )
                                })
                            }
                        </BookAppointmentSlotListView>
                    </BookAppointmentSlotsMainView>
                </BookAppointmentMainContainer>
                : 
                <NoAppointmentText> {t(Strings.BookAppointment.NoAppointments)} </NoAppointmentText>
            }
        </BookAppointmentSlotSelectionContainer>
    )
}

export default BookAppointmentSlotSelection