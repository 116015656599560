/* MessageBanner.tsx */

import { ColorConstant } from "../../../constants/ColorConstant";
import { isNonEmpty } from "../../../helpers/StringHelper";
import { MessageBannerText, MessageBannerTitleText, MessageBannerView } from "../styles/StylesCommonView";


interface MessageBannerProps {
    message: string;
    title?: string;
    showValidationMessage?: boolean
}

const MessageBanner: React.FC<MessageBannerProps> = (props: MessageBannerProps) => {
    return (
        <MessageBannerView style={{backgroundColor: props.showValidationMessage ? ColorConstant.SatinPink : ColorConstant.BLIZZARD_BLUE}}>
            <MessageBannerText style={{color: props.showValidationMessage ? ColorConstant.Coralite : ColorConstant.BIG_SUR_BLUE_JADE}}>
                {props.message}
            </MessageBannerText>
            {isNonEmpty(props.title) ?
                <MessageBannerTitleText>
                    {props.title}
                </MessageBannerTitleText>
                : null
            }
        </MessageBannerView>

    );
};

export default MessageBanner;