import { injectable } from "inversify";

import { BaseURL, getAccessToken } from "../../utils/ApiRequestUtil";
import { AppConfig } from "../../utils/AppConfig";
import { DD_APIEndPointName } from "../../utils/DDAPIEndpointName";
import { APIClientElectron } from "../APIClientElectron";
import { BookedPatientParameters } from "../apiParameterModels/BookedPatientParameters";
import { PatientFindParameters } from "../apiParameterModels/PatientFindParameters";
import { IPatientRepository } from "../interfaces/IPatientRepository";

@injectable()
export class PatientRepository implements IPatientRepository {
    getBookedPatients(params: BookedPatientParameters): Promise<string> {
        return new Promise((resolve, reject) => {
            const accessToken = `Bearer ${getAccessToken()}`
            APIClientElectron.postRequestWithCertificate(
                DD_APIEndPointName.BookedPatient,
                `${BaseURL.openAPI}${AppConfig.openApiUrlEndPoint.BookedPatients}`,
                params, accessToken)
                .then(async response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }
    getPatientSearchResults(params: PatientFindParameters): Promise<string> {
        return new Promise((resolve, reject) => {
            const accessToken = `Bearer ${getAccessToken()}`
            APIClientElectron.postRequestWithCertificate(
                DD_APIEndPointName.PatientSearch,
                `${BaseURL.openAPI}${AppConfig.openApiUrlEndPoint.PatientSearch}`,
                params, accessToken)
                .then(async response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }
}