export const ColorConstant = {
    EMERALD_STONE: '#07645F',
    CERAMIC_BLUE_TURQUOISE: '#12A593',
    BLUE_TUNA: '#0B8479',
    DARK_GREY: '#373636',
    BLIZZARD_BLUE: '#A1E3EF',
    GREY: '#808080',
    BLACK_SPACE: '#535353',
    DUGONG: '#707070',
    CLOUDED_VISION: '#D1D1D1',
    DR_WHITE: '#FAFAFA',
    DESIRED_DAWN: '#D8D8D8',
    BELUGA: '#F1F1F1',
    UNIQUE_GREY: '#C9C9C9',
    MICA_CREEK: '#70818F',
    GREEN_SEDUCTION: '#42AB32',
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    TROLLEY_GREY: '#818181',
    IRON: '#5E5E5E',
    WHITE_EDGAR: '#EDEDED',
    BLOSSOMING_DYNASTY: '#E2574C',
    BIG_SUR_BLUE_JADE: '#3E7091',
    GOD_OF_RAIN: '#797979',
    RED_POWER: '#D6403C',
    Blue_DarkNut: '#F3F3F3',
    Divider: '#DEDEDE',
    Spindrift: '#F5F5F5',
    BrushedMetal: '#C8C8C8',
    SatinPink: '#FBDFDD',
    Coralite: '#E2665C',

} as const;