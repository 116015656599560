import React from "react";
import { useTranslation } from 'react-i18next';
import { ButtonText } from "../../../constants/StringConstant";
import { isEmpty, isEqualIgnoreCase } from "../../../helpers/StringHelper";
import deleteKey from '../../../images/delete_left_black_icon.png';
import { DeleteImage, DeleteKeyTextButton, EmptyKey, KeyButton, NumberPadContainer, TextKeyboardComponentContainer, TextKeyboardContainer } from "../styles/StylesCommonView";

interface TextKeyboardComponentProps {
    onKeyClick: (text: string) => void;
    onDeleteClick: () => void;
    onCloseClick: () => void;
    isShiftOn: boolean
}

const TextKeyboardComponent: React.FC<TextKeyboardComponentProps> = (props: TextKeyboardComponentProps) => {
    const [isCapsLockOn, setIsCapsLockOn] = React.useState(false);
    const [isShiftOn, setIsShiftOn] = React.useState(props.isShiftOn);
    const { t } = useTranslation();

    const handleKeyClick = (key: string) => {
        if (isEqualIgnoreCase(key, ButtonText.CapsLock)) {
            setIsCapsLockOn(!isCapsLockOn);
        } else if (isEqualIgnoreCase(key, ButtonText.Shift)) {
            setIsShiftOn(!isShiftOn);
        } else if (isEqualIgnoreCase(key, ButtonText.CLOSE)) {
            props.onCloseClick()
        } else if (isEqualIgnoreCase(key, ButtonText.Space)) {
            props.onKeyClick(` `)
        } else {
            const isUpperCase = isCapsLockOn !== isShiftOn;
            const outputKey = isUpperCase ? key.toUpperCase() : key.toLowerCase();
            setIsShiftOn(false);
            props.onKeyClick(`${outputKey}`)
        }
    };

    const getKeyDisplay = (key: string) => {
        const isUpperCase = isCapsLockOn !== isShiftOn;
        return isUpperCase ? key.toUpperCase() : key.toLowerCase();
    };

    const keysRow1 = ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'].map(getKeyDisplay);
    const keysRow2 = ['', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L',].map(getKeyDisplay);
    const keysRow3 = ['Z', 'X', 'C', 'V', 'B', 'N', 'M', '.'].map(getKeyDisplay);

    return (
        <TextKeyboardComponentContainer data-testid="TextKeyboardComponentContainer">
            <TextKeyboardContainer data-testid="TextKeyboardContainer">
                {keysRow1.map((key) => (
                    <KeyButton data-testid={`KeyButton_keysRow1_${key.toUpperCase()}`} key={key} onClick={() => handleKeyClick(key)}>{key}</KeyButton>
                ))}

                {keysRow2.map((key) =>
                    isEmpty(key) ?
                        <EmptyKey data-testid={`KeyButton_keysRow2_EmptyKey`} key={key} /> :
                        <KeyButton data-testid={`KeyButton_keysRow2_${key.toUpperCase()}`} key={key} onClick={() => handleKeyClick(key)}>
                            {key}
                        </KeyButton>
                )}

                {keysRow3.map((key) => (
                    <KeyButton data-testid={`KeyButton_keysRow3_${key.toUpperCase()}`} key={key} onClick={() => handleKeyClick(key)}>{key}</KeyButton>
                ))}

                <DeleteKeyTextButton data-testid={`DeleteKeyTextButton`} sx={{ gridColumn: 'span 2', }} onClick={props.onDeleteClick}>
                    <DeleteImage data-testid={`DeleteImage`} src={deleteKey} alt="Delete_Icon" />
                </DeleteKeyTextButton>

                <KeyButton data-testid={`KeyButton_CapsLock`} sx={{ gridColumn: 'span 2', }} onClick={() => handleKeyClick(ButtonText.CapsLock)}>
                    {t(ButtonText.CapsLock)}
                </KeyButton>

                <KeyButton data-testid={`KeyButton_Shift`} onClick={() => handleKeyClick(ButtonText.Shift)}>
                    {t(ButtonText.Shift)}
                </KeyButton>

                <KeyButton data-testid={`KeyButton_Space`} sx={{ gridColumn: 'span 3', }} onClick={() => handleKeyClick(ButtonText.Space)}>
                    {t(ButtonText.Space)}
                </KeyButton>

                <KeyButton data-testid={`KeyButton_@`} key={'@'} onClick={() => handleKeyClick('@')}>{'@'}</KeyButton>
                <KeyButton data-testid={`KeyButton_-`} key={'-'} onClick={() => handleKeyClick('-')}>{'-'}</KeyButton>
                <KeyButton data-testid={`KeyButton_CLOSE`} sx={{ gridColumn: 'span 2', }} onClick={() => handleKeyClick(ButtonText.CLOSE)}>
                    {t(ButtonText.CLOSE)}
                </KeyButton>

            </TextKeyboardContainer>

            <NumberPadContainer data-testid={`NumberPadContainer`}>
                {[7, 8, 9, 4, 5, 6, 1, 2, 3].map((num) => (
                    <KeyButton data-testid={`KeyButton_keysRow3_${num}`} key={num} onClick={() => handleKeyClick(String(num))}>{num}</KeyButton>
                ))}
                <KeyButton data-testid={`KeyButton_keysRow3_0`} sx={{ gridColumn: 'span 3' }} onClick={() => handleKeyClick('0')}>0</KeyButton>
            </NumberPadContainer>
        </TextKeyboardComponentContainer>
    );
};

export default TextKeyboardComponent