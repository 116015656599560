import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

export const LDConstants = {
  Windows7_KillSwitch: 'Windows7_KillSwitch',
  Sync_Interval: 'sync_interval'
};

const initLaunchDarkly = async () => {
  const clientSideIDENV = process.env.REACT_APP_LD_CLIENTID as string;
  const LDProvider = await asyncWithLDProvider({
    clientSideID: clientSideIDENV, // To be replaced with production clientSideID when taking to production
    // context: { // Use context if flag needs to be fetched based on specific atrributes
    //     "kind": "cdb",
    //     "key": user.cdb,
  });
  return LDProvider;
};

export default initLaunchDarkly;

