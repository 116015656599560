/* NextAvailableAppointmentView */

import { useTranslation } from "react-i18next"
import { Strings } from "../../../../../constants/StringConstant"
import { DateFormat, formatDateString } from "../../../../../helpers/DateTimeHelper"
import { SlotUIEntity } from "../../../../../uiEntities/SlotUIEntity"
import { BookThisButton, NextAvailableAppointmentContainer, RightSideScrollableContainer, SessionHolderNameText, SlotDateText, SlotDivider, SlotLocationText, SlotTimeText, SlotTypeText } from "../../../styles/StylesBookAppointment"

const NextAvailableAppointmentView: React.FC<{ nextAvailableSlot: SlotUIEntity, handleBookThisAppointmentAction: (slot: SlotUIEntity) => void }> = ({ nextAvailableSlot, handleBookThisAppointmentAction }) => {
    const formattedDate = nextAvailableSlot.date ? formatDateString(nextAvailableSlot.date, DateFormat.DDMMYY_Slash, DateFormat.dddd_Do_MMMM_YYYY) : ''
    const startTime = nextAvailableSlot.startDateTime ?? ''
    const endTime = nextAvailableSlot.endDateTime ?? ''
    const { t } = useTranslation();
    return (
        <NextAvailableAppointmentContainer data-testid = "NextAvailableAppointmentContainer">
            <RightSideScrollableContainer data-testid = "RightSideScrollableContainer">
                <SlotDateText data-testid = "SlotDateText">{formattedDate}</SlotDateText>
                <SlotTimeText data-testid = "SlotTimeText">{`${startTime} - ${endTime}`}</SlotTimeText>
                <SlotTypeText data-testid = "SlotTypeText">{`(${nextAvailableSlot.slotTypeDescription})`}</SlotTypeText>
                <SessionHolderNameText data-testid = "SessionHolderNameText">{nextAvailableSlot.sessionHolderName}</SessionHolderNameText>
                <SlotDivider />
                <SlotLocationText data-testid = "SlotLocationText">{nextAvailableSlot.locationDisplayName}</SlotLocationText>
                <BookThisButton data-testid = "BookThisButton" variant="text"
                    onClick={() => handleBookThisAppointmentAction(nextAvailableSlot)}>
                    {t(Strings.BookAppointment.BookThisAppointment)}
                </BookThisButton>
            </RightSideScrollableContainer>
        </NextAvailableAppointmentContainer>
    )
}

export default NextAvailableAppointmentView