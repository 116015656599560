import React from "react"
import { useTranslation } from "react-i18next"
import { Survey } from "../../../../api/apiResultModels/SurveyResult"
import { Strings } from "../../../../constants/StringConstant"
import HeaderQuestion from "../../commonViews/HeaderQuestion"
import { SurveyButton, SurveyListContainerView, SurveyListInnerContainer, SurveyListView } from "../../styles/StylesQuestionnaire"

interface QuestionnaireListScreenProps {
    surveys: Survey[]
    surveyTapAction: (survey: Survey) => void
}
const SurveyListScreen: React.FC<QuestionnaireListScreenProps> = (props: QuestionnaireListScreenProps) => {
    const { t } = useTranslation();
    return (
        <SurveyListContainerView>
            <SurveyListInnerContainer>
                <HeaderQuestion headerText={t(Strings.Questionnaire.SelectQuestionnaire)} subHeaderText={""} justifyContent={'left'}/>
                <SurveyListView>
                    {props.surveys.map((survey, index) => {
                        return (
                            <SurveyButton 
                                key={`${index}.${survey.id}`} 
                                onClick={() => props.surveyTapAction(survey)}
                            >
                                {`${survey.attributes?.title}`}
                            </SurveyButton>
                        )
                    })}
                </SurveyListView>
            </SurveyListInnerContainer>
        </SurveyListContainerView>
    )
}

export default SurveyListScreen