import { useTranslation } from "react-i18next";
import { Patient } from "../../../../../../api/apiResultModels/BookedPatientResult";
import { Strings } from "../../../../../../constants/StringConstant";
import { store } from "../../../../../../redux/Store";
import { CheckInViewModel } from "../../../../../../viewModels/CheckInViewModel";
import { PatientInfoTitleText, PatientInfoValueText, PatientPersonalInfoViewContainer, PatientPersonalInfoViewSubTitleText, PatientPersonalInfoViewTitleText, PatientPersonalInformationInnerView, PatientPersonalInformationView } from "../../../../styles/StylesCheckIn";

const PatientPersonalInfoView: React.FC<{ patientInfo: Patient | undefined }> = ({ patientInfo }) => {
    const { t } = useTranslation();
    const scrambleDemographicDetails = store.getState().configSlice.kioskDetails?.scrambleDemographicDetails ?? false
    const demographicDetailsList = store.getState().configSlice.kioskDemographicDetailsTypes ?? []
    const details = demographicDetailsList.map(detail => CheckInViewModel().getDemoGraphicsDetail(scrambleDemographicDetails, patientInfo, detail))
    return (
        <PatientPersonalInfoViewContainer>
            <div style={{marginBottom: 20}}>
            <PatientPersonalInfoViewTitleText>{t(Strings.CheckIn.PatientInfo.HereIsThePersonalInformationWeCurrentlyHoldAboutYou)}</PatientPersonalInfoViewTitleText>
            {scrambleDemographicDetails ? <PatientPersonalInfoViewSubTitleText>{t(Strings.CheckIn.PatientInfo.SomeInformationHasBeenHiddenForPrivacyReasons)}</PatientPersonalInfoViewSubTitleText> : null}
            </div>
            <PatientPersonalInformationView>
                {details.map((detail, index) => (
                    <PatientPersonalInformationInnerView key={`${index.toString()}.${detail?.title}`}>
                        <PatientInfoTitleText>{`${t(detail?.title ?? '')}:`}</PatientInfoTitleText>
                        <PatientInfoValueText>{`${t(detail?.value ?? '')}`}</PatientInfoValueText>
                    </PatientPersonalInformationInnerView>
                ))}
            </PatientPersonalInformationView>
        </PatientPersonalInfoViewContainer>
    )
}

export default PatientPersonalInfoView