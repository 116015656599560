/* DateStripComponent */

import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { compareDates, getFutureDateByAddingWeeks } from "../../../../../helpers/DateTimeHelper";
import { isNotEqualIgnoreCase } from "../../../../../helpers/StringHelper";
import { CalendarComponent } from "../../../../../viewModels/BookAppointmentViewModel";
import { CalendarIconButton, StyledCalendarIcon } from "../../../styles/StylesBookAppointment";
import { DateStripComponentContainer, DateStripComponentInnerContainer, DateStripNextPreviousButton, StyledChevronBackIcon, StyledChevronForwardIcon } from "../../../styles/StylesCommonView";
import CalendarDateItem from "./CalendarDateItem";

interface DateStripComponentProps {
    weekWiseSessionsByDate: (CalendarComponent | undefined)[]
    selectedDate: Date,
    handleChangeInWeekSelection: (selectedWeekDate: Date) => void
    handleDateSelection: (selectedDate: Date) => void
    handleCalendarIconSelection: () => void
}

const CalendarIconView: React.FC<{ isShowIcon: boolean, isSmallDimension: boolean, handleCalendarIconSelection?: () => void }> = ({ isShowIcon, isSmallDimension, handleCalendarIconSelection }) => {
    return (
        <CalendarIconButton isShowIcon={isShowIcon} isSmallDimension={isSmallDimension}
            disabled={!isShowIcon}
            onClick={() => {
                if (isShowIcon && handleCalendarIconSelection) {
                    handleCalendarIconSelection()
                }
            }}
        >
            {isShowIcon ? <StyledCalendarIcon /> : null}
        </CalendarIconButton>
    );
};

const DateStripComponent: React.FC<DateStripComponentProps> = (props: DateStripComponentProps) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isPortrait = useMediaQuery('(orientation: portrait)');
    const isSmallDimension = isMobile || isPortrait

    const [selectedWeekDate, setSelectedWeekDate] = React.useState<Date>(props.selectedDate)
    const [enablePreviousWeekSelection, setEnablePreviousWeekSelection] = React.useState<boolean>(false)

    React.useEffect(() => {
        const previousWeekDate = getFutureDateByAddingWeeks(selectedWeekDate, -1)
        const enablePreviousButton = isNotEqualIgnoreCase(compareDates(new Date(), previousWeekDate), 'greater')
        setEnablePreviousWeekSelection(enablePreviousButton)
    }, [selectedWeekDate])

    const handlePreviousWeekAction = () => {
        const previousWeekDate = getFutureDateByAddingWeeks(selectedWeekDate, -1)
        if (enablePreviousWeekSelection) {
            props.handleChangeInWeekSelection(previousWeekDate)
            setSelectedWeekDate(previousWeekDate)
        }
    }

    const handleNextWeekAction = () => {
        const nextWeekDate = getFutureDateByAddingWeeks(selectedWeekDate, 1)
        props.handleChangeInWeekSelection(nextWeekDate)
        setSelectedWeekDate(nextWeekDate)
    }

    return (
        <DateStripComponentContainer>
            <DateStripComponentInnerContainer>
                <DateStripNextPreviousButton onClick={handlePreviousWeekAction} enablePreviousWeekSelection={enablePreviousWeekSelection} isNextButton={false}>
                    <StyledChevronBackIcon mobile={isMobile} />
                </DateStripNextPreviousButton>

                <CalendarDateItem
                    dates={props.weekWiseSessionsByDate}
                    onDateClick={props.handleDateSelection}
                    selectedDate={props.selectedDate}
                />

                <DateStripNextPreviousButton onClick={handleNextWeekAction} enablePreviousWeekSelection={true} isNextButton={true}>
                    <StyledChevronForwardIcon mobile={isMobile} />
                </DateStripNextPreviousButton>
            </DateStripComponentInnerContainer>
            <CalendarIconView isShowIcon={true} isSmallDimension={isSmallDimension} handleCalendarIconSelection={props.handleCalendarIconSelection} />

        </DateStripComponentContainer>
    )
}

export default DateStripComponent