/* NoMatchingScreen.tsx */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonText } from '../../../../constants/StringConstant';
import { CheckInViewModel } from '../../../../viewModels/CheckInViewModel';
import { DummyContainer, NoMatchingInfoText, NoMatchingScreenContainer, NoMatchingScreenDivider, NoMatchingScreenFooterButton, NoMatchingScreenFooterButtonContainer, NoMatchingScreenHeaderView, NoMatchingScreenInfoContainer, NoMatchingScreenInfoInnerContainer, NoMatchingScreenInnerContainer, NoMatchingScreenTitleText, NoMatchingScreenValuesContainer, NoMatchingScreenValuesTextView, NoMatchingScreenValuesTitleText, NoMatchingScreenValuesValueText, StyledCancelOutlinedIcon } from '../../styles/StylesMatchingScreen';
import { MatchingComponents } from './MatchingContainerScreen';

interface NoMatchingScreenProps {
    title: string
    information: string
    matchingComponents: MatchingComponents
    closeAction: () => void
    tryAgainAction: () => void
    matchingTitles: string[]
}

const NoMatchingScreen: React.FC<NoMatchingScreenProps> = (props: NoMatchingScreenProps) => {
    const { t } = useTranslation();
    return (
        <NoMatchingScreenContainer>
            <NoMatchingScreenInnerContainer>
                <DummyContainer>
                    <NoMatchingScreenHeaderView>
                        <StyledCancelOutlinedIcon data-testid="StyledCancelOutlinedIcon"/>
                        <NoMatchingScreenTitleText data-testid="NoMatchingScreenTitleText">{props.title}</NoMatchingScreenTitleText>
                    </NoMatchingScreenHeaderView>
                    <NoMatchingScreenDivider/>
                </DummyContainer>
                <NoMatchingScreenInfoContainer data-testid="NoMatchingInfoText">
                    <NoMatchingScreenInfoInnerContainer>
                        <NoMatchingInfoText data-testid="NoMatchingInfoValue"> {props.information}</NoMatchingInfoText>
                        <NoMatchingScreenFooterButtonContainer>
                            <NoMatchingScreenFooterButton data-testid="NoMatchingScreenFooterCloseButton" onClick={props.closeAction}>{t(ButtonText.Close)}</NoMatchingScreenFooterButton>
                            <NoMatchingScreenFooterButton style={{marginLeft: 10}} data-testid="NoMatchingScreenFooterTryAgainButton" onClick={props.tryAgainAction}>{t(ButtonText.TryAgain)}</NoMatchingScreenFooterButton>
                        </NoMatchingScreenFooterButtonContainer>
                    </NoMatchingScreenInfoInnerContainer>
                    <NoMatchingScreenValuesContainer data-testid="NoMatchingScreenValuesContainer">
                        {props.matchingTitles.map((title, index) => (
                            <NoMatchingScreenValuesTextView key={`${index.toString()}.${title}`}>
                                <NoMatchingScreenValuesTitleText data-testid={`${title}Text`}>{`${t(CheckInViewModel().getTextForCheckInComponents(title))}: `}</NoMatchingScreenValuesTitleText>
                                <NoMatchingScreenValuesValueText data-testid={`${title}Value`}>{t(CheckInViewModel().getValueForCheckInComponents(title, props.matchingComponents))}</NoMatchingScreenValuesValueText>
                            </NoMatchingScreenValuesTextView>
                        ))}
                    </NoMatchingScreenValuesContainer>
                </NoMatchingScreenInfoContainer>
            </NoMatchingScreenInnerContainer>
        </NoMatchingScreenContainer>
    )
}

export default NoMatchingScreen