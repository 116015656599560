import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SiteMap } from '../../../../api/apiResultModels/KioskConfigurationResult';
import { Strings } from "../../../../constants/StringConstant";
import useIdleTimer from '../../../../customHooks/useIdleTimer';
import { getScreenTimeOutInMilliSeconds } from '../../../../helpers/CommonHelper';
import { isEqualIgnoreCase } from '../../../../helpers/StringHelper';
import { RouterName } from '../../../../navigation/RouterName';
import { store, useAppDispatch, useAppSelector } from "../../../../redux/Store";
import MessageBanner from "../../commonViews/MessageBanner";
import TopBar from "../../commonViews/TopBar";
import { SiteMapContainerView, SiteMapIMage, SiteMapNameView, SiteNameButton } from './StylesSiteMap';

const SiteMapScreen: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const kioskTitle = store.getState().configSlice.kioskDetails?.title ?? ''
    const kioskSiteMapList: SiteMap[] = store.getState().configSlice.siteMaps ?? []
    const [selectedSite, setSelectedSite] = React.useState<SiteMap | undefined>(kioskSiteMapList.length > 0 ? kioskSiteMapList[0] : undefined)
    const screenTimeOut = useAppSelector((state) => state.configSlice.kioskDetails?.screenTimeOut)
    const { isIdle, timeRemaining } = useIdleTimer(getScreenTimeOutInMilliSeconds(screenTimeOut), dispatch, navigate);
    const [screenTimeOutMessage, setScreenTimeOutMessage] = React.useState<string>('')

    React.useEffect(() => {
        if (isIdle && timeRemaining === 1000) {
            setScreenTimeOutMessage(`${t(Strings.TimingOutIn)} ${timeRemaining / 1000}`)
            setTimeout(() => {
                setScreenTimeOutMessage('')
            }, 1000);
        } else if (isIdle && timeRemaining && timeRemaining <= 3000) {
            setScreenTimeOutMessage(`${t(Strings.TimingOutIn)} ${timeRemaining / 1000}`)
        } else {
            setScreenTimeOutMessage('')
        }
    }, [dispatch, isIdle, navigate, t, timeRemaining])

    return (
        <SiteMapContainerView>
            <TopBar
                isShowHomeButton={true}
                isShowBackOption={true}
                handleBackClick={() => { navigate(RouterName.Home) }}
                timeOutMessage={screenTimeOutMessage}
            />
            <MessageBanner message={Strings.Home.WelcomeTo} title={kioskTitle} />
            {kioskSiteMapList.length > 0 ?
                <>
                    <SiteMapNameView>
                        <Grid container spacing={0} marginTop={0} justifyContent={'center'}>
                            {kioskSiteMapList.map((kioskSiteMap, index) => {
                                return (
                                    <Grid item key={`${index}.${kioskSiteMap.siteMapId}`}>
                                        <SiteNameButton
                                            onClick={() => { setSelectedSite(kioskSiteMap) }}
                                            variant="text"
                                            isSelected={isEqualIgnoreCase(`${kioskSiteMap.siteMapId}`, `${selectedSite?.siteMapId}`)}
                                        >
                                            {kioskSiteMap.name}
                                        </SiteNameButton>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </SiteMapNameView>
                    {
                        (selectedSite?.image?.length ?? 0) > 0 ? <SiteMapIMage src={`data:image/jpeg;base64,${selectedSite?.image}`} alt={'altText'} /> : null
                    }
                </> : null
            }
        </SiteMapContainerView>
    )
}

export default SiteMapScreen
