import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { apiContainer } from "../../../../api/APIContainer"
import { PostAnonymousSurveyParameters, SurveyAnswer } from "../../../../api/apiParameterModels/PostAnonymousSurveyParameters"
import { NonAnonymousSurveyQuestion, PostNonAnonymousSurveyParameters } from "../../../../api/apiParameterModels/PostNonAnonymousSurveyParameters"
import { APIRepoKeys } from "../../../../api/APIRepoKeys"
import { Survey } from "../../../../api/apiResultModels/SurveyResult"
import { ISurveyRepository } from "../../../../api/interfaces/ISurveyRepository"
import { Strings, UsageLog } from "../../../../constants/StringConstant"
import { APIDataType } from "../../../../enum"
import { handleHomeClick } from "../../../../helpers/CommonHelper"
import { isEqualIgnoreCase } from "../../../../helpers/StringHelper"
import { RouterName } from "../../../../navigation/RouterName"
import { setAnonymousSurveys, setNonAnonymousSurveys } from "../../../../redux/actions/ConfigAction"
import { setAPICallInProgress, updateUsageLogs } from "../../../../redux/actions/GlobalAction"
import { RootState, store, useAppDispatch } from "../../../../redux/Store"
import { LoginViewModel } from "../../../../viewModels/LoginViewModel"
import { SurveyViewModel } from "../../../../viewModels/SurveyViewModel"
import Loader from "../../commonViews/Loader"
import MessageBanner from "../../commonViews/MessageBanner"
import SomethingWentWrongScreen from "../../commonViews/SomethingWentWrongScreen"
import TopBar from "../../commonViews/TopBar"
import { SurveyContainerTopView, SurveyContainerView } from "../../styles/StylesQuestionnaire"
import QuestionnaireScreen from "./QuestionnaireScreen"
import SurveyListScreen from "./SurveyListScreen"
import SurveySuccessScreen from "./SurveySuccessScreen"

const surveyRepository = apiContainer.get<ISurveyRepository>(
    APIRepoKeys.SURVEY_API_REPOSITORY,
);

const SurveyContainer: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [surveys, setSurveys] = React.useState<Survey[]>([])
    const [showSurveyList, setShowSurveyList] = React.useState<boolean>(true)
    const [showQuestionnaireScreen, setShowQuestionnaireScreen] = React.useState<boolean>(false)
    const [showSomethingWentWrongScreen, setShowSomethingWentWrongScreen] = React.useState(false)
    const [currentIndex, setCurrentIndex] = React.useState<number>(0)
    const [showQuestionnaireSuccessScreen, setShowQuestionnaireSuccessScreen] = React.useState<boolean>(false)
    const [selectedSurvey, setSelectedSurvey] = React.useState<Survey | undefined>(undefined)
    const apiCallInProgress = useSelector((state: RootState) => state.globalSlice.apiCallInProgress);
    const [surveyType, setSurveyType] = React.useState<APIDataType>(APIDataType.NonAnonymousSurvey)
    const [postNonAnonymousSurveyParameters, setPostNonAnonymousSurveyParameters] = React.useState<PostNonAnonymousSurveyParameters | undefined>(undefined)
    const location = useLocation();
    const kioskID = store.getState().globalSlice.currentKiosk?.id

    function resetState() {
        setShowSurveyList(false)
        setShowQuestionnaireScreen(false)
        setShowSomethingWentWrongScreen(false)
        setCurrentIndex(0)
        setShowQuestionnaireSuccessScreen(false)
        setSelectedSurvey(undefined)
    }

    React.useEffect(() => {
        if (location.state?.from && isEqualIgnoreCase(location.state.from, RouterName.Home)) {
            setSurveyType(APIDataType.AnonymousSurvey)
            const anonymousSurveys = store.getState().configSlice.anonymousSurveys ?? []
            setSurveys(anonymousSurveys)
        } else if (location.state?.from && isEqualIgnoreCase(location.state.from, RouterName.CheckIn)) {
            const nonAnonymousSurveys: Survey[] = location.state?.nonAnonymousSurveys ?? []
            if (location.state.params && nonAnonymousSurveys.length > 0) {
                setPostNonAnonymousSurveyParameters(location.state.params)
                setSurveyType(APIDataType.NonAnonymousSurvey)
                setSurveys(nonAnonymousSurveys)
            } else {
                handleHomeClick(dispatch, navigate)
            }
        }
        return () => {
            dispatch(setAnonymousSurveys([]))
            dispatch(setNonAnonymousSurveys([]))
        };
    }, [location, dispatch, navigate]);

    const handleBackAction = () => {
        if (showSurveyList) {
            navigate(RouterName.Home)
        } else if (showQuestionnaireScreen) {
            if (currentIndex === 0) {
                setShowQuestionnaireScreen(false)
                setShowSurveyList(true)
            } else {
                setCurrentIndex(currentIndex - 1)
            }
        }
    }

    const handleCloseButtonInSuccessScreen = () => {
        handleHomeClick(dispatch, navigate)
    }

    const surveyTapAction = (survey: Survey) => {
        if (survey.attributes?.questions && survey.attributes.questions.length > 0) {
            setSelectedSurvey(survey)
            setShowQuestionnaireScreen(true)
            setShowSurveyList(false)
        }
    }

    const onCompletionQuestionnaires = async (surveyAnswers: SurveyAnswer[]) => {
        if (surveyAnswers.length === 0) {
            return
        }
        try {
            dispatch(setAPICallInProgress(true));
            const isValidSession = await LoginViewModel().validateSession(dispatch)
            if (isValidSession) {
                if (surveyType === APIDataType.NonAnonymousSurvey && postNonAnonymousSurveyParameters) {
                    const nonAnonymousSurveyQuestion: NonAnonymousSurveyQuestion[] = SurveyViewModel().mapToNonAnonymousSurveyParameters(surveyAnswers)
                    const updatedParams: PostNonAnonymousSurveyParameters = { ...postNonAnonymousSurveyParameters, Questions: nonAnonymousSurveyQuestion }
                    await surveyRepository.postNonAnonymousSurvey(updatedParams)
                    dispatch(updateUsageLogs(`${UsageLog.Survey} ${postNonAnonymousSurveyParameters.PatientNumber}`))
                    const PostNonAnonymousSurveyFrequencyParameters = SurveyViewModel().mapToNonAnonymousSurveyFrequencyParameters(surveyAnswers, postNonAnonymousSurveyParameters.PatientNumber, kioskID)
                    if (PostNonAnonymousSurveyFrequencyParameters) {
                        await surveyRepository.postNonAnonymousSurveyFrequency(PostNonAnonymousSurveyFrequencyParameters)
                    }
                } else if (surveyType === APIDataType.AnonymousSurvey) {
                    const params: PostAnonymousSurveyParameters = { data: surveyAnswers }
                    await surveyRepository.postAnonymousSurvey(params)
                }
                setShowQuestionnaireScreen(false)
                setShowQuestionnaireSuccessScreen(true)
                setCurrentIndex(0)
            }
        } catch (error) {
            resetState()
            setShowSomethingWentWrongScreen(true)
        } finally {
            dispatch(setAPICallInProgress(false));
        }
    }

    return (
        <SurveyContainerView>
            <SurveyContainerTopView>
                <TopBar
                    isShowHomeButton={true}
                    isShowBackOption={!showQuestionnaireSuccessScreen && !showSomethingWentWrongScreen}
                    handleBackClick={handleBackAction}
                    timeOutMessage={""}
                />
                {!showSurveyList && !showQuestionnaireSuccessScreen ?
                    <MessageBanner message={t(Strings.Questionnaire.TheSurgeryWouldLikeToAsk)} />
                    : null
                }
            </SurveyContainerTopView>
            {showSurveyList && surveys.length > 0 ?
                <SurveyListScreen
                    surveys={surveys}
                    surveyTapAction={surveyTapAction}
                />
                : null
            }
            {showQuestionnaireScreen && selectedSurvey ?
                <QuestionnaireScreen
                    surveyType={surveyType}
                    survey={selectedSurvey}
                    onCompletionQuestionnaires={onCompletionQuestionnaires}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                />
                : null
            }
            {showQuestionnaireSuccessScreen ?
                <SurveySuccessScreen handleCloseAction={handleCloseButtonInSuccessScreen} />
                : null
            }
            {showSomethingWentWrongScreen ?
                <SomethingWentWrongScreen
                    handleCloseAction={() => handleHomeClick(dispatch, navigate)}
                />
                : null
            }
            {apiCallInProgress ? <Loader showLoading={apiCallInProgress} /> : null}
        </SurveyContainerView>
    )
}

export default SurveyContainer