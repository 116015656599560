import { Button, styled } from "@mui/material";
import { ColorConstant } from "../../../../constants/ColorConstant";

export const SiteMapContainerView = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ColorConstant.WHITE,
    height: '100vh',
    width: '100vw',
    userSelect: 'none'
});

export const SiteMapNameView = styled('div')({
    backgroundColor: ColorConstant.DESIRED_DAWN,
    paddingBottom: 0,
    marginTop: 15,
});

export const SiteNameButton = styled(Button)<{ isSelected: boolean }>(({ isSelected }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'x-large',
    fontWeight: 'bold',
    color: isSelected ? ColorConstant.WHITE : ColorConstant.GOD_OF_RAIN,
    backgroundColor: isSelected ? ColorConstant.CERAMIC_BLUE_TURQUOISE : 'transparent',
    '&:hover': {
        backgroundColor: isSelected ? ColorConstant.CERAMIC_BLUE_TURQUOISE : 'transparent',
    },
    borderRadius: 5,
    textTransform: 'none',
    margin: '0px 10px',
}));

export const SiteMapIMage = styled('img') ({ 
    maxWidth: '100%', 
    minHeight: 100, 
    objectFit: 'contain', 
    padding: 20 
})