import { KioskConfigurationResult, Language } from "../api/apiResultModels/KioskConfigurationResult";
import { defaultLanguages } from "../constants/DefaultValues";
import { MatchTitle } from "../enum";
import { isEqualIgnoreCase } from "../helpers/StringHelper";
import { AnalyticsManager } from "../manager/AnalyticsManager";
import { store } from "../redux/Store";

export interface DDGlobalContextForSessionParams {
    CDB: string[],
    KioskID: string,
    SurveyEnabled: boolean
}

export const HomeViewModel = () => {

    function getLanguages(): Language[] {
        return store.getState().configSlice.languages.length > 0  ? store.getState().configSlice.languages : defaultLanguages;
    }

    function showDate(currentMatchTitle: MatchTitle | undefined,) {
        return (isEqualIgnoreCase(currentMatchTitle, MatchTitle.Date) || isEqualIgnoreCase(currentMatchTitle, MatchTitle.Date_1))
    }

    function showSurName(currentMatchTitle: MatchTitle | undefined,) {
        return (isEqualIgnoreCase(currentMatchTitle, MatchTitle.SurName) || isEqualIgnoreCase(currentMatchTitle, MatchTitle.SurName_1))
    }

    function navBarDateTextFontSize(isMobile: boolean, isPortrait: boolean) {
        let navBarDateTextFontSize: number;
        if (isPortrait) {
            navBarDateTextFontSize = 20;
        } else if (isMobile) {
            navBarDateTextFontSize = 15;
        } else {
            navBarDateTextFontSize = 25;
        }
        return navBarDateTextFontSize
    }

    function setDDsetGlobalContextForSessionParams(kioskConfigurationResult: KioskConfigurationResult) {
        const kioskID = kioskConfigurationResult.data?.attributes?.kioskDetails?.kioskId
        const CDBs = kioskConfigurationResult.data?.attributes?.organisation ? (kioskConfigurationResult.data.attributes.organisation).map(organisation => organisation.organisationKey).filter((value): value is string => value !== undefined) : []
        const surveyEnabled = (kioskConfigurationResult.data?.attributes?.modules ?? []).find(module => module.moduleId === 2)
        if(kioskID) {
            const param: DDGlobalContextForSessionParams = {
                CDB: CDBs,
                KioskID: `${kioskID}`,
                SurveyEnabled: surveyEnabled !== undefined
            }
            AnalyticsManager.setGlobalContextForSession('AAX', param);
        }
    }

    return {
        getLanguages,
        showDate,
        showSurName,
        navBarDateTextFontSize,
        setDDsetGlobalContextForSessionParams,
    }
}