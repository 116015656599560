
export const AppConfig = {
    apiTimeout: 60000,
    B2C_Token_Endpoint: `oauth2/v2.0/token`,
    CertificateEndPoint: '/enrolment/certificates/automatedArrivals',
    openApiUrlEndPoint: {
        PatientSearch: '/hub/automatedarrivals/PatientSearch',
        BookedPatients: '/hub/automatedarrivals/BookedPatients',
        SetAppointmentStatus: '/hub/automatedarrivals/SetAppointmentStatus',
        GetAppointmentSessions: '/hub/automatedarrivals/GetAppointmentSessions',
        GetSessionSlots: '/hub/automatedarrivals/GetSlotsForSession',
        BookAppointment: '/hub/automatedarrivals/BookAppointment',
        PostNonAnonymousSurvey: '/hub/automatedarrivals/FileRecord',
    },
    emisxAPiUrlEndPoint: {
        kiosk: '/kiosk',
        kioskDetail: '/Kiosk/GetKioskDetails',
        kioskConfiguration: '/Configuration',
        GetAnonymousSurvey: '/Questionnaire/AnonymousSurvey',
        PostAnonymousSurvey: '/Questionnaire/AnonymousSurvey',
        GetNonAnonymousSurvey: '/Questionnaire/NonAnonymousSurvey',
        PostNonAnonymousSurveyFrequency: '/Questionnaire/NonAnonymousSurveyFrequency',
        Member: '/Member',
        Message: '/Messages/Info',
        PostUsageLogs: '/monitoring/usageLog'
    },
    ServiceName: "Automated-Arrivals",
    defaultValues: {
        DemographicDetailsDuration: 180,
        EarlyArrival: 60,
        LateArrival: 10,
        ScreenTimeOut: 30,
        ShowDoctorDelay: true,
        AllowUnTimed: false,
        AutoConfirmArrival: true,
        AutoConfirmMultipleArrival: true,
        BookingReasonMaxAllowedCharacter: 35,
        MaximumPostCodes: 6,
        AdminLoginPinSize: 4,
        QuarterHourInSeconds: 900,
        MaxAllowedCharacter: 30,
    },
    retryCount: {
        kioskConfig: 3,
        clientTokenRequest: 3
    },
    retryDelay: {
        kioskConfig: 1000,
        clientTokenRequest: 5000,
    }
}