/* BookAppointmentCalendarScreen.tsx */

import { useMediaQuery, useTheme } from '@mui/material'
import React from "react"
import { useTranslation } from "react-i18next"
import { ColorConstant } from "../../../../../constants/ColorConstant"
import { Strings } from "../../../../../constants/StringConstant"
import { compareMonths, DateFormat, formatDate, getFutureDateByAddingMonths } from "../../../../../helpers/DateTimeHelper"
import { isNotEqualIgnoreCase } from "../../../../../helpers/StringHelper"
import { BookAppointmentCalendarScreenCalendarContainer, BookAppointmentCalendarScreenContainer, BookAppointmentCalendarScreenInnerContainer, BookAppointmentCalendarScreenMonthYearText, BookAppointmentCalendarScreenNextContainer, BookAppointmentCalendarScreenPreviousContainer, BookAppointmentCalendarScreenTitleText } from "../../../styles/StylesBookAppointment"
import { StyledChevronBackIcon, StyledChevronForwardIcon } from "../../../styles/StylesCommonView"
import { AppointmentsByDate } from "../BookAppointmentContainer"
import BookAppointmentCalendarFooterView from "./BookAppointmentCalendarFooterView"
import BookAppointmentCalendarView from "./BookAppointmentCalendarView"

interface BookAppointmentCalendarScreenProps {
    selectedDate: Date,
    monthWiseSessionsByDate: AppointmentsByDate | undefined
    handleDateSelection: (selectedDate: Date) => void,
    handleMonthSelection: (selectedDate: Date) => void,
}

const BookAppointmentCalendarScreen: React.FC<BookAppointmentCalendarScreenProps> = (props: BookAppointmentCalendarScreenProps) => {
    const [enablePreviousMonthSelectionButton, setEnablePreviousMonthSelectionButton] = React.useState<boolean>(false)
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    React.useEffect(() => {
        const previousMonthDate = getFutureDateByAddingMonths(props.selectedDate, -1)
        const enablePreviousMonthSelectionButton = isNotEqualIgnoreCase(compareMonths(new Date(), previousMonthDate), 'greater')
        setEnablePreviousMonthSelectionButton(enablePreviousMonthSelectionButton)
    }, [props.selectedDate])

    const handlePreviousMonthAction = () => {
        const previousMonthDate = getFutureDateByAddingMonths(props.selectedDate, -1)
        if (enablePreviousMonthSelectionButton) {
            props.handleMonthSelection(previousMonthDate)
        }
    }

    const handleNextMonthAction = () => {
        const nextMonthDate = getFutureDateByAddingMonths(props.selectedDate, 1)
        props.handleMonthSelection(nextMonthDate)
    }

    return (
        <BookAppointmentCalendarScreenContainer>
            <BookAppointmentCalendarScreenTitleText>{t(Strings.BookAppointment.SelectAppointmentDate)}</BookAppointmentCalendarScreenTitleText>
            <BookAppointmentCalendarScreenInnerContainer>
                <BookAppointmentCalendarScreenPreviousContainer disabled={!enablePreviousMonthSelectionButton} style={{ backgroundColor: enablePreviousMonthSelectionButton ? ColorConstant.CERAMIC_BLUE_TURQUOISE : ColorConstant.CLOUDED_VISION, }} onClick={handlePreviousMonthAction}>
                    <StyledChevronBackIcon sx={{ fontSize: 100 }} mobile={isMobile} />
                </BookAppointmentCalendarScreenPreviousContainer>
                <BookAppointmentCalendarScreenCalendarContainer>
                    <BookAppointmentCalendarScreenMonthYearText>{formatDate(props.selectedDate, DateFormat.MMMM_YYYY)}</BookAppointmentCalendarScreenMonthYearText>
                    <BookAppointmentCalendarView
                        currentDate={props.selectedDate}
                        handleDateSelection={props.handleDateSelection}
                        monthWiseSessionsByDate={props.monthWiseSessionsByDate}
                    />
                    <BookAppointmentCalendarFooterView />
                </BookAppointmentCalendarScreenCalendarContainer>
                <BookAppointmentCalendarScreenNextContainer variant="contained" onClick={handleNextMonthAction}>
                    <StyledChevronForwardIcon sx={{ fontSize: 100 }} mobile={isMobile} />
                </BookAppointmentCalendarScreenNextContainer>
            </BookAppointmentCalendarScreenInnerContainer>
        </BookAppointmentCalendarScreenContainer>
    )
}

export default BookAppointmentCalendarScreen