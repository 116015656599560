/* CheckInSuccessScreen.tsx */

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ColorConstant } from '../../../../constants/ColorConstant';
import { ButtonText, Strings } from '../../../../constants/StringConstant';
import { AppointmentUIEntity } from '../../../../viewModels/CheckInViewModel';
import { CheckInSuccessMessageInnerVerticalLine, CheckInSuccessMessageText, CheckInSuccessMessageVerticalLine, CheckInSuccessScreenContainer, CheckInSuccessScreenDivider, CheckInSuccessScreenFooterButton, CheckInSuccessScreenFooterView, CheckInSuccessScreenHiText, CheckInSuccessScreenInnerContainer, CheckInSuccessScreenMessageContainer, CheckInSuccessScreenMessageInnerContainer, CheckInSuccessScreenMessagesContainer, CheckInSuccessScreenMessageTitleText, CheckInSuccessScreenMultiSlotsView, CheckInSuccessScreenSingleSlotContainer, CheckInSuccessScreenSingleSlotInnerContainer, CheckInSuccessScreenTitleText, CheckInSuccessScreenTitleView, CheckInSuccessScreenUserNameContainer, CheckInSuccessScreenUserNameText, DirectionalMessagesContainerView, DirectionalMessagesViewContainer, DirectionalMessageText, MessageItemView, SingleSlotOnTimeText, SingleSlotSessionHolderNameText, SingleSlotTimeText, SingleSlotWithText, StylesCheckCircleOutlineRoundedIcon } from '../../styles/StylesCheckIn';
import { DummyContainer } from '../../styles/StylesMatchingScreen';

export interface Message {
    id: string
    text: string,
    type: number
}

interface CheckInSuccessScreenProps {
    patientName: string,
    handleDoneAction: () => void,
    selectedAppointments: AppointmentUIEntity[],
    messages: Message[],
}
const MessageView: React.FC<{ messages: Message[] }> = ({ messages }) => {
    const { t } = useTranslation();
    return (
        <CheckInSuccessScreenMessageContainer>
            <CheckInSuccessMessageVerticalLine />
            <CheckInSuccessScreenMessageInnerContainer>
                <CheckInSuccessScreenMessageTitleText>
                    {t(Strings.CheckIn.WeWouldLikeToLetYouKnow)}
                </CheckInSuccessScreenMessageTitleText>
                <CheckInSuccessScreenMessagesContainer>
                    {messages.map((message, index) => (
                        <MessageItemView key={`${index.toString()}.${message.text}`}>
                            <CheckInSuccessMessageInnerVerticalLine
                                style={{ backgroundColor: message.type === 3 ? ColorConstant.RED_POWER : ColorConstant.TROLLEY_GREY }}>
                            </CheckInSuccessMessageInnerVerticalLine>
                            <CheckInSuccessMessageText
                                style={{ color: message.type === 3 ? ColorConstant.RED_POWER : ColorConstant.IRON, fontWeight: message.type === 3 ? 'bold' : '500' }}>
                                {message.type === 3 ? `${t(Strings.CheckIn.IMPORTANT)}: ${message.text}` : message.text}
                            </CheckInSuccessMessageText>
                        </MessageItemView>
                    ))}
                </CheckInSuccessScreenMessagesContainer>
            </CheckInSuccessScreenMessageInnerContainer>
        </CheckInSuccessScreenMessageContainer>
    )
}

interface CheckedInSlotsListViewProps {
    appointments: AppointmentUIEntity[],
}

interface CheckedListViewProps {
    appointment: AppointmentUIEntity;
    width: number
}

const HeaderView: React.FC<{ patientName: string }> = ({ patientName }) => {
    const { t } = useTranslation();
    return (
        <DummyContainer>
            <CheckInSuccessScreenUserNameContainer>
                <CheckInSuccessScreenHiText>
                    {t(Strings.Hi)}
                </CheckInSuccessScreenHiText>
                <CheckInSuccessScreenUserNameText>
                    {patientName}
                </CheckInSuccessScreenUserNameText>
            </CheckInSuccessScreenUserNameContainer>

            <CheckInSuccessScreenTitleView>
                <StylesCheckCircleOutlineRoundedIcon />
                <CheckInSuccessScreenTitleText>{t(Strings.CheckIn.YouHaveCheckedInForYourAppointment)}</CheckInSuccessScreenTitleText>
            </CheckInSuccessScreenTitleView>
            <CheckInSuccessScreenDivider />
        </DummyContainer>
    )
}

const DirectionalMessagesView: React.FC<{ directionalMessages: Message[] }> = ({ directionalMessages }) => {
    return (
        <DirectionalMessagesViewContainer>
            {directionalMessages.map((message, index) => (
                <DirectionalMessageText key={`${index.toString()}.${message.text}`}>
                    {message.text}
                </DirectionalMessageText>
            ))}
        </DirectionalMessagesViewContainer>
    )
}

const CheckedListView: React.FC<CheckedListViewProps> = (props: CheckedListViewProps) => {
    const { t } = useTranslation();
    return (
        <CheckInSuccessScreenSingleSlotContainer sx={{ width: `${props.width}%` }}>
            <CheckInSuccessScreenSingleSlotInnerContainer>
                <SingleSlotTimeText>{props.appointment.appointmentTime}</SingleSlotTimeText>
                <SingleSlotWithText>{t(Strings.CheckIn.with)}</SingleSlotWithText>
                <SingleSlotSessionHolderNameText>{props.appointment.sessionHolderName}</SingleSlotSessionHolderNameText>
            </CheckInSuccessScreenSingleSlotInnerContainer>
            <SingleSlotOnTimeText>
                {props.appointment.waitingTime && props.appointment.waitingTime > 0 ? t(Strings.CheckIn.RunningMinutesLate).replace('##', props.appointment.waitingTime.toString()) : t(Strings.CheckIn.OnTime)}
            </SingleSlotOnTimeText>
        </CheckInSuccessScreenSingleSlotContainer>
    )
}

const CheckedInSlotsListView: React.FC<CheckedInSlotsListViewProps> = (props: CheckedInSlotsListViewProps) => {
    if (props.appointments.length === 1) {
        const appointment = props.appointments[0]
        return (<CheckedListView appointment={appointment} width={95} />)
    } else {
        return (
            <CheckInSuccessScreenMultiSlotsView>
                <Grid container justifyContent={'left'}>
                    {props.appointments.map((appointment, index) => (
                        <CheckedListView
                            key={`${index.toString()}.${appointment.appointmentId}`}
                            appointment={appointment}
                            width={props.appointments.length % 2 === 0 ? 40 : 100}
                        />
                    ))}
                </Grid>
            </CheckInSuccessScreenMultiSlotsView>
        )
    }
}

const FooterView: React.FC<{ handleDoneAction: () => void }> = ({ handleDoneAction }) => {
    const { t } = useTranslation();
    return (
        <CheckInSuccessScreenFooterView>
            <CheckInSuccessScreenFooterButton onClick={handleDoneAction}>
                {t(ButtonText.Done)}
            </CheckInSuccessScreenFooterButton>
        </CheckInSuccessScreenFooterView>
    )
}

const CheckInSuccessScreen: React.FC<CheckInSuccessScreenProps> = (props: CheckInSuccessScreenProps) => {

    const messages: Message[] = props.messages
    const directionalMessages = messages.filter(message => message.type === 2)

    return (
        <CheckInSuccessScreenContainer>
            <CheckInSuccessScreenInnerContainer>
                <HeaderView patientName={props.patientName} />
                <DirectionalMessagesContainerView>
                    {directionalMessages.length > 0 ? <DirectionalMessagesView directionalMessages={messages.filter(message => message.type === 2)} /> : null}
                </DirectionalMessagesContainerView>
                <CheckedInSlotsListView
                    appointments={props.selectedAppointments}
                />
                <FooterView handleDoneAction={props.handleDoneAction} />
            </CheckInSuccessScreenInnerContainer>
            {messages.length > 0 ? <MessageView messages={messages.filter(message => message.type !== 2)} /> : null}
        </CheckInSuccessScreenContainer>
    )
}

export default CheckInSuccessScreen;