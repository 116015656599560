/* DayGridComponent.tsx */

import { Grid } from '@mui/material';
import React from 'react';
import { getDaySuffix } from '../../../../helpers/CommonHelper';
import { DayGridButton, DayGridComponentContainer, DayTextTypography } from '../../styles/StylesMatchingScreen';

interface GridProps {
  gridAlignment: 'center' | 'left' | 'right',
  onDayClick: (item: string) => void;
}

const DayGridComponent: React.FC<GridProps> = (props: GridProps) => {
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  return (
    <DayGridComponentContainer data-testid="DayGridComponentContainer">
      <Grid data-testid="DayGridComponentOuterGrid" container spacing={3} marginTop={2} justifyContent={props.gridAlignment}>
        {days.map((day, index) => {
          return (
            <Grid data-testid="DayGridComponentInnerGrid" item key={`${index}.${day}`}>
              <DayGridButton data-testid="DayGridButton"
                onClick={() => props.onDayClick(`${day}`)}
                variant="contained"
              >
                <DayTextTypography data-testid="DayTextTypography" variant="button">
                  <span data-testid="DayText" className="bold-text">{day}</span>
                  <span data-testid="suffixText" className="small-text">{getDaySuffix(day).toLocaleLowerCase()}</span>
                </DayTextTypography>
              </DayGridButton>
            </Grid>
          )
        })}
      </Grid>
    </DayGridComponentContainer>
  );
};

export default DayGridComponent;