/* CalendarDateItem.tsx */

import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { ColorConstant } from '../../../../../constants/ColorConstant';
import { formatDate } from '../../../../../helpers/DateTimeHelper';
import { CalendarComponent } from '../../../../../viewModels/BookAppointmentViewModel';
import { CalendarDateItemContainer, DateStripDateMonthText, DateStripDayText, DayGridItemButton } from '../../../styles/StylesBookAppointment';
interface CalendarDateItemProps {
    dates: (CalendarComponent | undefined)[],
    selectedDate?: Date;
    onDateClick: (date: Date) => void;
}

interface DayGridItemProps {
    dateComponent: CalendarComponent | undefined;
    onClick: () => void;
    isSmallDimension: boolean;
    selectedDate?: Date;
}

const DayGridItem: React.FC<DayGridItemProps> = (props: DayGridItemProps) => {
    const dateComponentDate = props.dateComponent?.date ?? new Date()
    const dateSplits = formatDate(dateComponentDate, 'ddd,DD MMM').split(",")
    const isSelectedDate = props.selectedDate?.getDate() === dateComponentDate.getDate() &&
        props.selectedDate?.getMonth() === dateComponentDate.getMonth() &&
        props.selectedDate?.getFullYear() === dateComponentDate.getFullYear()
    let textColor: string = (props.dateComponent?.hasAppointment ?? false) ? ColorConstant.EMERALD_STONE : ColorConstant.UNIQUE_GREY
    textColor = isSelectedDate ? ColorConstant.WHITE : textColor

    return (
        <DayGridItemButton isSelectedDate={isSelectedDate} isSmallDimension={props.isSmallDimension} sx={{ width: props.isSmallDimension ? 50 : 90 }}
            disabled={!(props.dateComponent?.hasAppointment ?? false)}
            onClick={() => {
                props.onClick()
            }}
        >
            {dateSplits.length > 0 ? <DateStripDayText textColor={textColor} isSmallDimension={props.isSmallDimension}>{dateSplits[0]}</DateStripDayText> : null}
            {dateSplits.length > 1 ? <DateStripDateMonthText textColor={textColor} isSmallDimension={props.isSmallDimension}>{dateSplits[1]}</DateStripDateMonthText> : null}
        </DayGridItemButton>
    );
};

const CalendarDateItem: React.FC<CalendarDateItemProps> = (props: CalendarDateItemProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isPortrait = useMediaQuery('(orientation: portrait)');
    const isSmallDimension = isMobile || isPortrait

    return (
        <CalendarDateItemContainer isSmallDimension={isSmallDimension}>
            {props.dates.map((date, index) => (
                <DayGridItem
                    key={`${index.toString()}. ${date}`}
                    onClick={() => props.onDateClick(props.dates[index]?.date ?? new Date())}
                    dateComponent={date}
                    selectedDate={props.selectedDate}
                    isSmallDimension={isSmallDimension}
                />
            ))}
        </CalendarDateItemContainer>
    );
};

export default CalendarDateItem;