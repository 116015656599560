/* HeaderQuestion.tsx */

import { Stack } from '@mui/material';
import { HeaderQuestionHeaderText, HeaderQuestionSubHeaderText } from '../styles/StylesCommonView';
interface HeaderQuestionProps {
    headerText: string
    subHeaderText: string
    justifyContent?: 'center' | 'left'
}

const HeaderQuestion: React.FC<HeaderQuestionProps> = (props: HeaderQuestionProps) => {
    return (
        <Stack data-testid="HeaderQuestionStack"
            spacing={1}
            direction={{ xs: 'column', sm: 'row', md: 'row' }}
            justifyContent={props.justifyContent ? props.justifyContent : 'center'}
            sx={{
                width: '100%',
                marginBottom: 2,
                alignItems: 'center',
                userSelect: 'none'
            }}
        >
            <HeaderQuestionHeaderText data-testid="HeaderQuestionHeaderText">{props.headerText}</HeaderQuestionHeaderText>
            <HeaderQuestionSubHeaderText data-testid="HeaderQuestionSubHeaderText">{props.subHeaderText}</HeaderQuestionSubHeaderText>
        </Stack>
    );
};

export default HeaderQuestion;