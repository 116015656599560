import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { APIClientElectron } from "../../../../api/APIClientElectron";
import { apiContainer } from "../../../../api/APIContainer";
import { APIRepoKeys } from "../../../../api/APIRepoKeys";
import { KioskListResult } from "../../../../api/apiResultModels/KioskListResult";
import { IKioskRepository } from "../../../../api/interfaces/IKioskRepository";
import { ButtonText, LocalStorageKey } from "../../../../constants/StringConstant";
import { clearAllAndRefresh, getAllOrgs, getSelectedKiosk, getSelectedOrg } from "../../../../helpers/CommonHelper";
import { isEqualIgnoreCase } from "../../../../helpers/StringHelper";
import { AuthenticationManager } from "../../../../manager/AuthenticationManager";
import { RouterName } from "../../../../navigation/RouterName";
import { RootState, useAppDispatch } from "../../../../redux/Store";
import { setAPICallInProgress, setCurrentKiosk, setCurrentOrg, setKiosks, setOrgs } from "../../../../redux/actions/GlobalAction";
import { DD_APIEndPointName } from "../../../../utils/DDAPIEndpointName";
import { LoginViewModel } from "../../../../viewModels/LoginViewModel";
import APIFailScreen from "../../commonViews/APIFailScreen";
import Loader from "../../commonViews/Loader";
import { AlertContent } from "../home/HomeScreen";

const kioskRepository = apiContainer.get<IKioskRepository>(
    APIRepoKeys.KIOSK_API_REPOSITORY,
);

const SplashScreen: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isAdminUserSessionValid = LoginViewModel().isAdminUserSessionValid()
    const apiCallInProgress = useSelector((state: RootState) => state.globalSlice.apiCallInProgress);
    const [lastAPICall, setLastAPICall] = React.useState<string | undefined>(undefined)
    const [alertContent, setAlertContent] = React.useState<AlertContent | undefined>(undefined)
    const [showAlert, setShowAlert] = React.useState<boolean>(false)

    const fetchKioskList = React.useCallback(async () => {
        try {
            dispatch(setAPICallInProgress(true));
            setLastAPICall(DD_APIEndPointName.KioskList)
            const responseString = await kioskRepository.getKioskList();
            const kioskListResult: KioskListResult = JSON.parse(responseString);
            dispatch(setKiosks(kioskListResult.data ?? []))
            setTimeout(() => {
                navigate(RouterName.KioskList)
            }, 500);
        } catch (error) {
            if (error instanceof Error) {
                console.log(JSON.stringify(error));
            }
            setAlertContent({ title: 'Failed', message: `Failed to get Kiosk List` })
            setShowAlert(true)
        } finally {
            dispatch(setAPICallInProgress(false));
        }
    }, [dispatch, navigate]);

    const checkAndNavigate = React.useCallback(() => {
        const registeredKiosk = getSelectedKiosk()
        const selectedOrg = getSelectedOrg()
        const allOrgEntities = getAllOrgs()
        dispatch(setCurrentKiosk(registeredKiosk))
        dispatch(setCurrentOrg(selectedOrg))
        dispatch(setOrgs(allOrgEntities))
        if (registeredKiosk && allOrgEntities.length > 0) {
            setTimeout(() => {
                navigate(RouterName.OrgList)
            }, 500);
        } else if (isAdminUserSessionValid) {
            fetchKioskList()
        } else {
            clearAllAndRefresh(dispatch)
        }
    }, [dispatch, isAdminUserSessionValid, navigate, fetchKioskList])

    const initiateCertificateRequestCall = React.useCallback(() => {
        const isAdminUserSessionValid = LoginViewModel().isAdminUserSessionValid()
        if (!isAdminUserSessionValid) {
            clearAllAndRefresh(dispatch)
        }
        dispatch(setAPICallInProgress(true));
        setLastAPICall(DD_APIEndPointName.DeviceEnrolment)
        APIClientElectron.postEnrolKioskRequest()
            .then((result) => {
                localStorage.setItem(LocalStorageKey.Certificate, JSON.stringify(result));
                dispatch(setAPICallInProgress(false));
                if (result.IsSuccess) {
                    checkAndNavigate()
                } else {
                    setAlertContent({ title: 'Failed', message: `Failed to get Certificate` })
                    setShowAlert(true)
                }
            })
            .catch((error) => {
                dispatch(setAPICallInProgress(false));
                setAlertContent({ title: 'Failed', message: `Failed to get Certificate ${error}` })
                setShowAlert(true)
            })
    }, [dispatch, checkAndNavigate])

    React.useEffect(() => {
        if(!AuthenticationManager.isCertificateEnv()) {
            checkAndNavigate()
            return
        }
        window.electron.checkIfCertificateExists()
            .then((isCertificateAvailable) => {
                if (isCertificateAvailable) {
                    checkAndNavigate()
                    return
                }
                initiateCertificateRequestCall()
            })
            .catch((error) => {
                console.log(error)
                setAlertContent({ title: 'Failed', message: `Failed to get Certificate ${error}` })
                setShowAlert(true)
            })
    }, [initiateCertificateRequestCall, checkAndNavigate]);

    function handleRetryAction() {
        setAlertContent(undefined)
        setShowAlert(false)
        if (isEqualIgnoreCase(lastAPICall, DD_APIEndPointName.KioskList)) {
            fetchKioskList()
        } else if (isEqualIgnoreCase(lastAPICall, DD_APIEndPointName.DeviceEnrolment)) {
            initiateCertificateRequestCall()
        }
    }

    return (
        <div style={{ display: 'flex', flex: 1, }}>
            {apiCallInProgress ? <Loader showLoading={apiCallInProgress} /> : null}
            {showAlert && alertContent ?
                <APIFailScreen
                    title={alertContent?.title ?? ''}
                    information={alertContent?.message ?? ""}
                    closeAction={() => {
                        setAlertContent(undefined);
                        setShowAlert(false);
                    }}
                    tryAgainAction={handleRetryAction}
                    isShowCloseAction={false}
                    buttonText={ButtonText.TryAgain}
                />
                : null
            }
        </div>
    )
}

export default SplashScreen