import { Dialog, Grid, } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getLanguageText } from '../../../helpers/CommonHelper';
import { useAppDispatch, useAppSelector } from '../../../redux/Store';
import { setSelectedLanguage, setShowLanguageScreen } from '../../../redux/actions/GlobalAction';
import { LanguageButton, LanguageDialogContent } from '../styles/StylesCommonView';

const LanguagePopupScreen: React.FC = () => {
    const dispatch = useAppDispatch();
    const showLanguageScreen = useAppSelector((state) => state.globalSlice.showLanguageScreen) ?? false
    const languages = useAppSelector((state) => state.configSlice.languages)
    const sortedLanguagesByOrder = [...languages].sort((a,b) => (a.order ?? 0) - (b.order ?? 0))
    const { i18n } = useTranslation();

    const handleClose = () => {
        dispatch(setShowLanguageScreen(false))
    };

    return (
        <Dialog open={showLanguageScreen} onClose={handleClose}>
            <LanguageDialogContent>
                <Grid container spacing={1}>
                    {sortedLanguagesByOrder.map((language, index) => (
                        <Grid item xs={6} sm={4} md={3} key={`${index}.${language.languageId}`}>
                            <LanguageButton
                                variant="text"
                                fullWidth
                                onClick={() => {
                                    dispatch(setSelectedLanguage(language))
                                    i18n.changeLanguage(language.languageCode);
                                    handleClose();
                                }}
                            >
                                {getLanguageText(language.languageName)}
                            </LanguageButton>
                        </Grid>
                    ))}
                </Grid>
            </LanguageDialogContent>
        </Dialog>
    );
};

export default LanguagePopupScreen;
