
/* StringHelper.ts */

export function isEqualIgnoreCase(str1?: string, str2?: string): boolean {
    if(isEmpty(str1) && isEmpty(str2)) {
        return true
    } else if(str1 && str2) {
        return str1.toLowerCase() === str2.toLowerCase()
    } else {
        return false
    }
};

export function isNotEqualIgnoreCase(str1?: string, str2?: string): boolean {
    return !isEqualIgnoreCase(str1, str2)
};

export function isNonEmpty(str?: string): boolean {
    return str !== null && str !== undefined && str.length > 0;
};

export function isEmpty(str?: string): boolean {
    return str === null || str === undefined || (str ?? '').length === 0;
};

export function isNonEmptyAndNotZero(str?: string): boolean {
    return str !== null && str !== undefined && str.length > 0 && !/^0+$/.test(str);
};