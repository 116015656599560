import { store } from "../redux/Store";

export const getAccessToken = () => {
    return store.getState().tokenSlice.accessToken;
};

export const getAdminAccessToken = () => {
  return store.getState().tokenSlice.adminAccessToken;
};

export const BaseURL = {
  openAPI: 'https://ewids.int.emishealthsolutions.com',
  token: 'https://identity.dev.emishealthsolutions.com',
  certificate: 'https://idinternal.emishealth.com',
  emisXBaseURL_dev: 'https://aax.dev.emishealthsolutions.com',
} as const