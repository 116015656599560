import React from "react"
import { useTranslation } from "react-i18next"
import { QuestionAnswerOption } from "../../../../api/apiResultModels/QuestionnaireResult"
import { ColorConstant } from "../../../../constants/ColorConstant"
import { ButtonText } from "../../../../constants/StringConstant"
import { isNonEmpty } from "../../../../helpers/StringHelper"
import { store } from "../../../../redux/Store"
import { FooterButton } from "../../styles/StylesMatchingScreen"
import { QuestionnaireScreenOptionsView, QuestionOptionButton } from "../../styles/StylesQuestionnaire"

export interface QuestionnaireOptionsScreenProps {
    selectedOption: string | undefined
    options: QuestionAnswerOption[]
    tapAction: (option: QuestionAnswerOption) => void
    skipQuestionAction: () => void
}

const QuestionnaireOptionsScreen: React.FC<QuestionnaireOptionsScreenProps> = (props: QuestionnaireOptionsScreenProps) => {
    const { t } = useTranslation();
    const skipSurveyQuestion = store.getState().configSlice.kioskDetails?.skipSurveyQuestion
    const options = props.options.filter(option => isNonEmpty(option.optionValue))
    return (
        <QuestionnaireScreenOptionsView>
            {options.map((option, index) => {               
                return (
                    <QuestionOptionButton data-testid={`QuestionOptionButton-${option.optionValue}`}  style={{backgroundColor: `${option.optionId}` === props.selectedOption ? ColorConstant.EMERALD_STONE : ColorConstant.CERAMIC_BLUE_TURQUOISE}}
                        key={`${index}.${option}`}
                        onClick={() => props.tapAction(option)}
                        variant="contained"
                    >
                        {option.optionValue}
                    </QuestionOptionButton>
                )
            })}
            {skipSurveyQuestion ? <FooterButton style={{marginTop: 50}} onClick={() => props.skipQuestionAction()}>{t(ButtonText.SkipQuestion)}</FooterButton> : null}
        </QuestionnaireScreenOptionsView>
    )
}

export default QuestionnaireOptionsScreen