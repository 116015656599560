import { injectable } from "inversify";
import { Dispatch, UnknownAction } from "redux";
import { getSelectedKiosk } from "../../helpers/CommonHelper";
import { resetUsageLogs, setUsageLogLastSentDateTime } from "../../redux/actions/GlobalAction";
import { store } from "../../redux/Store";
import { BaseURL, getAccessToken } from "../../utils/ApiRequestUtil";
import { AppConfig } from "../../utils/AppConfig";
import { DD_APIEndPointName } from "../../utils/DDAPIEndpointName";
import { APIClientElectron } from "../APIClientElectron";
import { UsageLogParameters } from "../apiParameterModels/UsageLogParameters";
import { ICommonRepository } from "../interfaces/ICommonRepository";

@injectable()
export class CommonRepository implements ICommonRepository {
    getMembers(): Promise<string> {
        return new Promise((resolve, reject) => {
            const accessToken = `Bearer ${getAccessToken()}`
            APIClientElectron.getRequestWithOutCertificate(
                DD_APIEndPointName.Members,
                `${BaseURL.emisXBaseURL_dev}${AppConfig.emisxAPiUrlEndPoint.Member}`,
                accessToken)
                .then(response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    PostUsageLogs(dispatch: Dispatch<UnknownAction>): Promise<string> {
        const allUsageLogs = store.getState().globalSlice.usageLogs
        const params: UsageLogParameters = { data: allUsageLogs }
        let apiURLWithQuery = `${BaseURL.emisXBaseURL_dev}${AppConfig.emisxAPiUrlEndPoint.PostUsageLogs}`
        const selectedKioskGuid = getSelectedKiosk()?.guid
        if (selectedKioskGuid) {
            apiURLWithQuery = `${apiURLWithQuery}?kioskGuid=${selectedKioskGuid}`
        }
        return new Promise((resolve, reject) => {
            const accessToken = `Bearer ${getAccessToken()}`
            APIClientElectron.postRequestWithOutCertificate(
                DD_APIEndPointName.UsageLog,
                apiURLWithQuery,
                params,
                accessToken)
                .then(response => {
                    resolve(response);
                    dispatch(resetUsageLogs())
                    dispatch(setUsageLogLastSentDateTime(new Date()))
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }
}