import { BaseURL } from "./ApiRequestUtil";

export const B2CConstants = {
    grantType: 'client_credentials',
    scope: `${BaseURL.token}/x/.default`,
    policy: `B2C_1A_ClientCredentials`,
    Int: {
        tenantId: '79809fbe-0297-4f3c-848d-97f841b62a7a',
    },
    Dev: {
        tenantId: '5eb0f67d-cfc5-418b-aad3-0c194267c1f2',
    },
    Live: {
        tenantId: '',
    },
}

export enum ValidDomain {
    Emistesting = '.emistesting.co.uk',
    Emishosting = '.emishosting.com',
    Emishealth = '.emishealth.com',
    EmishealthSolutions = '.emishealthsolutions.com',
    EmisxUk = '.emis-x.uk',
}