import { Grid } from '@mui/material';
import { KioskDetail } from '../../../../api/apiResultModels/KioskListResult';
import { KioskButton, KioskListGridContainer } from './StylesKiosk';

interface KioskListGridViewProps {
    kioskList: KioskDetail[]
    handleKioskSelection(selectedKiosk: KioskDetail): Promise<void>
}

const KioskListGridView: React.FC<KioskListGridViewProps> = (props: KioskListGridViewProps) => {
    return (
        <KioskListGridContainer>
            <Grid container columns={2} justifyContent={'center'}>
                {props.kioskList.map((kiosk, index) => {
                    return (
                        <Grid item key={`${index}.${kiosk.id}`}>
                            <KioskButton
                                onClick={async () => {
                                    props.handleKioskSelection(props.kioskList[index])
                                }}
                                variant="contained"
                            >
                                {kiosk.attributes?.kioskName}
                            </KioskButton>
                        </Grid>
                    )
                })}
            </Grid>
        </KioskListGridContainer>
    );
};

export default KioskListGridView