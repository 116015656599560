// src/authConfig.js

export const msalConfig = {
    auth: {
        clientId: "95bbb494-0111-446f-a628-9146452da0cd",
        authority: "https://identity.dev.emishealthsolutions.com/5eb0f67d-cfc5-418b-aad3-0c194267c1f2/B2C_1A_SIGNUP_SIGNIN_LEGACY", // This is the user flow you created
        knownAuthorities: ["https://identity.dev.emishealthsolutions.com"],
        redirectUri: "https://kiosk-app.dev.emishealthsolutions.com",  // Or your app's URL
    },
    cache: {
        cacheLocation: "sessionStorage",  // Can also use localStorage
        storeAuthStateInCookie: false,    // Set to true if you have issues on IE 11 or Edge
    }
};

export const loginRequest = {
    scopes: [
        "https://identity.dev.emishealthsolutions.com/x/emis-x", 
        "https://identity.dev.emishealthsolutions.com/x/cdb", 
        "https://identity.dev.emishealthsolutions.com/x/ods",
        "https://identity.dev.emishealthsolutions.com/x/emisweb"
    ]
};
