/* BookAppointmentSuccess.tsx */

import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { useTranslation } from 'react-i18next';
import { ColorConstant } from '../../../../constants/ColorConstant';
import { ButtonText, Strings } from '../../../../constants/StringConstant';
import { DateFormat, formatDateString } from '../../../../helpers/DateTimeHelper';
import { SlotUIEntity } from '../../../../uiEntities/SlotUIEntity';
import { BookAppointmentSuccessContainer, BookAppointmentSuccessInnerContainer, BookAppointmentTopView, DividerInSuccess, DoneButtonInSuccess, FooterButtonViewInSuccess, SlotContentViewInSuccess, SlotDateTextInSuccess, SlotHolderNameTextInSuccess, SlotInfoViewInSuccess, SlotLocationTextInSuccess, SlotLocationTextInSuccessContainer, SlotTimeTextInSuccess, SlotTimeTypeViewInSuccess, SlotTypeTextInSuccess, SlotViewInSuccess, TitleTextInSuccess, TitleView } from '../../styles/StylesBookAppointment';

interface BookAppointmentSuccessProps {
    handleDoneAction: () => void
    selectedSlot: SlotUIEntity
}

const BookAppointmentSuccess: React.FC<BookAppointmentSuccessProps> = (props: BookAppointmentSuccessProps) => {
    const { t } = useTranslation();
    const formattedDate = props.selectedSlot.date ? formatDateString(props.selectedSlot.date, DateFormat.DDMMYY_Slash, DateFormat.dddd_Do_MMMM_YYYY) : ''
    const startTime = props.selectedSlot.startDateTime ? props.selectedSlot.startDateTime : ''
    const endTime = props.selectedSlot.endDateTime ? props.selectedSlot.endDateTime : ''
    return (
        <BookAppointmentSuccessContainer data-testid="BookAppointmentSuccessContainer">
            <BookAppointmentSuccessInnerContainer data-testid="BookAppointmentSuccessInnerContainer">
                <BookAppointmentTopView data-testid="BookAppointmentTopView">
                    <TitleView data-testid="TitleView">
                        <CheckCircleOutlineRoundedIcon data-testid="CheckCircleOutlineRoundedIcon" sx={{ color: ColorConstant.GREEN_SEDUCTION, fontSize: 55, }} />
                        <TitleTextInSuccess data-testid="TitleTextInSuccess">{t(Strings.BookAppointment.YouHaveBookedYourAppointment)}</TitleTextInSuccess>
                    </TitleView>
                    <SlotContentViewInSuccess data-testid="SlotContentViewInSuccess">
                        <SlotViewInSuccess data-testid="SlotViewInSuccess">
                            <SlotInfoViewInSuccess data-testid="SlotInfoViewInSuccess">
                                <SlotDateTextInSuccess data-testid="SlotDateTextInSuccess">{formattedDate}</SlotDateTextInSuccess>
                                <SlotTimeTypeViewInSuccess data-testid="SlotTimeTypeViewInSuccess">
                                    <SlotTimeTextInSuccess data-testid="SlotTimeTextInSuccess">{`${startTime} - ${endTime}`}</SlotTimeTextInSuccess>
                                    <SlotTypeTextInSuccess data-testid="SlotTypeTextInSuccess">{`(${props.selectedSlot.slotTypeDescription})`}</SlotTypeTextInSuccess>
                                </SlotTimeTypeViewInSuccess>
                                <SlotHolderNameTextInSuccess data-testid="SlotHolderNameTextInSuccess">{`${props.selectedSlot.sessionHolderName}`}</SlotHolderNameTextInSuccess>
                            </SlotInfoViewInSuccess>
                            <DividerInSuccess />
                            <SlotLocationTextInSuccessContainer data-testid="SlotLocationTextInSuccessContainer">
                                <SlotLocationTextInSuccess data-testid="SlotLocationTextInSuccess">{`${props.selectedSlot.locationDisplayName}`}</SlotLocationTextInSuccess>
                            </SlotLocationTextInSuccessContainer>
                        </SlotViewInSuccess>
                    </SlotContentViewInSuccess>
                </BookAppointmentTopView>
                <FooterButtonViewInSuccess data-testid="FooterButtonViewInSuccess">
                    <DoneButtonInSuccess data-testid="DoneButtonInSuccess" onClick={props.handleDoneAction}>
                        {t(ButtonText.Done)}
                    </DoneButtonInSuccess>
                </FooterButtonViewInSuccess>
            </BookAppointmentSuccessInnerContainer>
        </BookAppointmentSuccessContainer>
    )
}


export default BookAppointmentSuccess;