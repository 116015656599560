import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { ButtonText } from '../../../constants/StringConstant';

interface CustomAlertDialogProps {
    open: boolean;
    onClose: () => void;
    message: string;
    title?: string;
}

// Customise the dialog component as and when needed.
const CustomAlert: React.FC<CustomAlertDialogProps> = ({ open, onClose, message, title }) => {  
  return (
    <div>
      <Dialog
        open={open}
        onClose={()=>{}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown={true}  // Prevent closing via Esc key
      >
        {title ? <DialogTitle id="alert-dialog-title">{title}</DialogTitle> : null}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            {ButtonText.Ok}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomAlert;