import { injectable } from "inversify";
import { BaseURL, getAccessToken } from "../../utils/ApiRequestUtil";
import { AppConfig } from "../../utils/AppConfig";
import { DD_APIEndPointName } from "../../utils/DDAPIEndpointName";
import { APIClientElectron } from "../APIClientElectron";
import { AppointmentSessionsParameters } from "../apiParameterModels/AppointmentSessionsParameters";
import { BookAppointmentParams } from "../apiParameterModels/BookAppointmentParams";
import { SessionSlotsParameters } from "../apiParameterModels/SessionSlotsParameters";
import { SetAppointmentStatusParameters } from "../apiParameterModels/SetAppointmentStatusParameters";
import { IAppointmentRepository } from "../interfaces/IAppointmentRepository";

@injectable()
export class AppointmentRepository implements IAppointmentRepository {
    setAppointmentStatus(params: SetAppointmentStatusParameters): Promise<string> {
        const accessToken = `Bearer ${getAccessToken()}`
        return new Promise((resolve, reject) => {
            APIClientElectron.postRequestWithCertificate(
                DD_APIEndPointName.SetAppointmentStatus,
                `${BaseURL.openAPI}${AppConfig.openApiUrlEndPoint.SetAppointmentStatus}`,
                params, accessToken)
                .then(async response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    getAppointmentSessions(params: AppointmentSessionsParameters): Promise<string> {
        const accessToken = `Bearer ${getAccessToken()}`
        return new Promise((resolve, reject) => {
            APIClientElectron.postRequestWithCertificate(
                DD_APIEndPointName.GetAppointmentSessions,
                `${BaseURL.openAPI}${AppConfig.openApiUrlEndPoint.GetAppointmentSessions}`,
                params, accessToken)
                .then(async response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    getSessionSlots(params: SessionSlotsParameters): Promise<string> {
        const accessToken = `Bearer ${getAccessToken()}`
        return new Promise((resolve, reject) => {
            APIClientElectron.postRequestWithCertificate(
                DD_APIEndPointName.GetSlotsForSession,
                `${BaseURL.openAPI}${AppConfig.openApiUrlEndPoint.GetSessionSlots}`,
                params, accessToken)
                .then(async response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    postBookAppointmentSlot(params: BookAppointmentParams): Promise<boolean> {
        const accessToken = `Bearer ${getAccessToken()}`
        return new Promise((resolve, reject) => {
            APIClientElectron.postRequestWithCertificate(
                DD_APIEndPointName.BookAppointment,
                `${BaseURL.openAPI}${AppConfig.openApiUrlEndPoint.BookAppointment}`,
                params, accessToken)
                .then(async response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }
}