import { Grid } from "@mui/material";
import { Slot } from "../../../../../api/apiResultModels/SessionSlotsResult";
import { BookAppointmentSlotSelectionGrid, BookAppointmentSlotSelectionGridContainer, BookAppointmentSlotSelectionSessionText, SlotTimeGridButton } from "../../../styles/StylesBookAppointment";

interface SlotListViewProps {
    slots: Slot[],
    handleSlotSelection: (selectedSlot: Slot) => void
    siteName: string
}

const SlotListView: React.FC<SlotListViewProps> = (props: SlotListViewProps) => {
    return (
        <BookAppointmentSlotSelectionGridContainer>
            <BookAppointmentSlotSelectionGrid>
                <BookAppointmentSlotSelectionSessionText>{props.siteName}</BookAppointmentSlotSelectionSessionText>
                <Grid container columnGap={2} rowSpacing={2} justifyContent={'left'}>
                    {props.slots.map((slot, index) => {
                        const slotTime = slot.startTime
                        return (
                            <Grid item key={`${index}.${slotTime}`}>
                                <SlotTimeGridButton
                                    onClick={() => props.handleSlotSelection(slot)}
                                    variant="contained"
                                >
                                    {slotTime}
                                </SlotTimeGridButton>
                            </Grid>
                        )
                    })}
                </Grid>
            </BookAppointmentSlotSelectionGrid>
        </BookAppointmentSlotSelectionGridContainer>
    )
}

export default SlotListView