/* CheckInViewModel.ts */

import moment from "moment"
import { AppUtil } from "../Util"
import { apiContainer } from "../api/APIContainer"
import { APIRepoKeys } from "../api/APIRepoKeys"
import { BookedPatientParameters } from "../api/apiParameterModels/BookedPatientParameters"
import { PatientFindParameters } from "../api/apiParameterModels/PatientFindParameters"
import { SetAppointmentStatusParameters } from "../api/apiParameterModels/SetAppointmentStatusParameters"
import { Booked, BookedPatientResult, Patient, SessionHolderElement } from "../api/apiResultModels/BookedPatientResult"
import { GetMemberResult } from "../api/apiResultModels/GetMemberResult"
import { KioskDemographicDetailsType } from "../api/apiResultModels/KioskConfigurationResult"
import { PatientFindResult } from "../api/apiResultModels/PatientFindResult"
import { SetAppointmentStatusResult } from "../api/apiResultModels/SetAppointmentStatusResult"
import { IAppointmentRepository } from "../api/interfaces/IAppointmentRepository"
import { ICommonRepository } from "../api/interfaces/ICommonRepository"
import { IPatientRepository } from "../api/interfaces/IPatientRepository"
import { Strings } from "../constants/StringConstant"
import { MatchTitle } from "../enum"
import { maskInfo, padDigitsInPrefix, removeLastCharacter } from "../helpers/CommonHelper"
import { getTimeDifferenceInMinutes } from "../helpers/DateTimeHelper"
import { isEmpty, isEqualIgnoreCase, isNonEmpty, isNotEqualIgnoreCase } from "../helpers/StringHelper"
import { store } from "../redux/Store"
import { AppConfig } from "../utils/AppConfig"
import { MatchingComponents } from "../view/web/screens/matchingScreen/MatchingContainerScreen"

export interface PatientInfoUIEntity {
    name: string;
    email: string;
    mobile: string;
    telephone: string;
    postCode: string;
}

const patientRepository = apiContainer.get<IPatientRepository>(
    APIRepoKeys.PATIENT_API_REPOSITORY,
);
const appointmentRepository = apiContainer.get<IAppointmentRepository>(
    APIRepoKeys.APPOINTMENT_API_REPOSITORY,
);
const commonAPIRepository = apiContainer.get<ICommonRepository>(
    APIRepoKeys.COMMON_API_REPOSITORY,
);

export interface AppointmentUIEntity {
    appointmentId: number
    appointmentDate?: string
    appointmentTime?: string
    isEarlyAppointment: boolean
    isLateAppointment: boolean
    sessionHolderId?: string
    sessionHolderGuid?: string
    sessionHolderName: string
    waitingTime: number
    isDivertedSessionHolder: boolean
}

export const CheckInViewModel = () => {

    function getTextForCheckInComponents(patientMatchTitle: string): string {
        if (patientMatchTitle) {
            const checkInScreenCode = AppUtil.stringToEnum(patientMatchTitle, MatchTitle)
            switch (checkInScreenCode) {
                case MatchTitle.Date:
                case MatchTitle.Date_1:
                    return 'Date of birth'
                case MatchTitle.Day:
                    return 'Day of birth'
                case MatchTitle.Month:
                    return 'Month of birth'
                case MatchTitle.Year:
                    return 'Year of birth'
                case MatchTitle.Gender:
                    return 'Gender'
                case MatchTitle.SurName:
                case MatchTitle.SurName_1:
                    return 'First letter of surname'
                case MatchTitle.PostalCode:
                    return 'Postal Code'
            }
        }
        return ''
    }

    const getValueForCheckInComponents = (patientMatchTitle: string, checkInComponents: MatchingComponents): string => {
        if (patientMatchTitle) {
            const checkInScreenCode = AppUtil.stringToEnum(patientMatchTitle, MatchTitle)
            switch (checkInScreenCode) {
                case MatchTitle.Date:
                case MatchTitle.Date_1:
                    return checkInComponents.date
                case MatchTitle.Day:
                    return padDigitsInPrefix(checkInComponents.day, 2)
                case MatchTitle.Month:
                    return checkInComponents.month
                case MatchTitle.Year:
                    return isEmpty(checkInComponents.year) ? Strings.Matching.YearNotPresent : checkInComponents.year
                case MatchTitle.Gender:
                    return getGenderText(checkInComponents.gender)
                case MatchTitle.SurName:
                case MatchTitle.SurName_1:
                    return checkInComponents.surNameFirstLetter
                case MatchTitle.PostalCode:
                    return checkInComponents.postalCode
            }
        }
        return ''
    }

    function getGenderText(gender: string) {
        if (isEqualIgnoreCase('M', gender)) {
            return Strings.Matching.Gender.Male
        } else if (isEqualIgnoreCase('F', gender)) {
            return Strings.Matching.Gender.Female
        } else if (isEqualIgnoreCase('I', gender)) {
            return Strings.Matching.Gender.Other
        } else if (isEqualIgnoreCase('U', gender)) {
            return Strings.Matching.Gender.NotDisclosed
        }
        return Strings.Matching.Gender.NotDisclosed
    }

    async function initiatePatientFindAPI(matchingComponents: MatchingComponents) {
        const params: PatientFindParameters = mapToPatientFindParameters(matchingComponents)
        const promises = [patientRepository.getPatientSearchResults(params)]
        const [patientFindResponse] = await Promise.all(promises)
        const patientFindResult: PatientFindResult = JSON.parse(patientFindResponse);
        return patientFindResult
    }

    async function initiateBookedPatientsAPI() {
        const bookedPatientParams = getBookAppointmentAPIParams()
        const promises = [patientRepository.getBookedPatients(bookedPatientParams)]
        const [bookedPatientResponse] = await Promise.all(promises)
        const bookedPatientResult: BookedPatientResult = JSON.parse(bookedPatientResponse);
        return bookedPatientResult
    }

    async function initiateCheckInAPI(selectedAppointmentIDs: number[]) {
        let promises: Promise<string>[] = []
        for (const selectedAppointmentID of selectedAppointmentIDs) {
            const params: SetAppointmentStatusParameters = {
                slotId: selectedAppointmentID,
                statusId: 2,
            }
            promises.push(appointmentRepository.setAppointmentStatus(params))
        }
        const allResult = await Promise.all(promises)
        const result: SetAppointmentStatusResult[] = allResult.map(result => JSON.parse(result))
        return result
    }

    function mapToPatientFindParameters(matchingComponents: MatchingComponents): PatientFindParameters {
        let params: PatientFindParameters = {}
        if (isNonEmpty(matchingComponents.day)) {
            params = { ...params, Day: matchingComponents.day }
        }
        if (isNonEmpty(matchingComponents.month)) {
            params = { ...params, Month: matchingComponents.month }
        }
        if (isNonEmpty(matchingComponents.year)) {
            params = { ...params, Year: matchingComponents.year }
        }
        if (isNonEmpty(matchingComponents.date)) {
            params = { ...params, DateOfBirth: matchingComponents.date }
        }
        if (isNonEmpty(matchingComponents.gender)) {
            if (isNotEqualIgnoreCase(matchingComponents.gender.charAt(0), 'I') && isNotEqualIgnoreCase(matchingComponents.gender.charAt(0), 'U')) {
                params = { ...params, Gender: matchingComponents.gender.charAt(0) }
            }
        }
        if (isNonEmpty(matchingComponents.surNameFirstLetter)) {
            params = { ...params, Surname: matchingComponents.surNameFirstLetter.toLocaleLowerCase() }
        }
        if (isNonEmpty(matchingComponents.postalCode)) {
            params = { ...params, PostCode: matchingComponents.postalCode }
        }
        return params
    }

    function formatPatientName(name?: Patient): string {
        let formattedPatientName = ''
        if (name) {
            if (name?.title && isNonEmpty(name.title)) {
                formattedPatientName = `${formattedPatientName} ${name.title}.`
            }
            if (name?.firstNames && isNonEmpty(name?.firstNames)) {
                formattedPatientName = `${formattedPatientName} ${name.firstNames}`
            }
            if (name?.familyName && isNonEmpty(name?.familyName)) {
                formattedPatientName = `${formattedPatientName} ${name.familyName}`
            }
        }
        return `${formattedPatientName}.`
    }

    function mapToAppointmentUIEntity(bookings: Booked[], sessionHolderList: SessionHolderElement[], divertedSessionHolderIDs: string[]): AppointmentUIEntity[] {
        let entities: AppointmentUIEntity[] = []
        for (const booking of bookings) {
            if (booking.appointment?.appointmentId?.dbId) {
                let timeDifference = getTimeDifferenceInMinutes(booking.appointment.time ?? '')
                const sessionHolderId = booking.appointment.sessionHolder?.sessionHolderId
                const sessionHolder = sessionHolderList.find(sessionHolder => sessionHolder.sessionHolderId === sessionHolderId)
                let entity: AppointmentUIEntity = {
                    appointmentId: booking.appointment.appointmentId.dbId,
                    appointmentDate: booking.appointment.date,
                    appointmentTime: booking.appointment.time,
                    isEarlyAppointment: isEarlyArrival(timeDifference),
                    isLateAppointment: isLateArrival(timeDifference),
                    sessionHolderName: getSessionHolderName(sessionHolderId, sessionHolderList),
                    waitingTime: sessionHolderId ? getSessionHolderWaitingName(sessionHolderId, sessionHolderList) : 0,
                    sessionHolderId: sessionHolderId,
                    isDivertedSessionHolder: sessionHolderId ? isDivertedSessionHolder(sessionHolderId, divertedSessionHolderIDs) : false
                }
                if (sessionHolder) {
                    entity = { ...entity, sessionHolderGuid: sessionHolder.sessionHolderGuid }
                }
                entities.push(entity)
            }
        }
        return entities
    }

    function getSessionHolderName(sessionHolderId?: string, sessionHolderList?: SessionHolderElement[]) {
        if (sessionHolderId && sessionHolderList) {
            const sessionHolder = sessionHolderList.find(sessionHolder => sessionHolder.sessionHolderId === sessionHolderId)
            return sessionHolder ? `${sessionHolder.title} ${sessionHolder.firstName} ${sessionHolder.lastName}` : ''
        } else {
            return ''
        }
    }

    function getSessionHolderWaitingName(sessionHolderId: string, sessionHolderList: SessionHolderElement[]) {
        const sessionHolder = sessionHolderList.find(sessionHolder => sessionHolder.sessionHolderId === sessionHolderId)
        return sessionHolder?.waitingTime ?? 0
    }

    function isEarlyArrival(timeDifference: number): boolean {
        const earlyArrival = store.getState().configSlice.kioskDetails?.earlyArrival ?? AppConfig.defaultValues.EarlyArrival
        if (timeDifference < 0 && Math.abs(timeDifference) > earlyArrival) {
            return true
        }
        return false
    }

    function isLateArrival(timeDifference: number): boolean {
        const lateArrival = store.getState().configSlice.kioskDetails?.lateArrival ?? AppConfig.defaultValues.LateArrival
        if (timeDifference > 0 && timeDifference > lateArrival) {
            return true
        }
        return false
    }

    function getUniqueNonEmptyPostCodesFromPatients(patients: Patient[]): string[] {
        const postalCodes = patients.map(patient => patient.address?.postalCode).filter((postCode): postCode is string => postCode !== undefined && isNonEmpty(postCode)) ?? []
        const uniquePostCodes = Array.from(new Set(postalCodes))
        return uniquePostCodes
    }

    function addRandomPostCodesIfNeeded(uniquePostCodes: string[], maximumPostCodes: number): string[] {
        if (uniquePostCodes.length >= maximumPostCodes) {
            return uniquePostCodes;
        }
        let tempArray = [...uniquePostCodes];
        let numberToAdd = 7;
        while (tempArray.length < maximumPostCodes) {
            for (const code of uniquePostCodes) {
                const updatedCode = change(code, numberToAdd);
                if (!tempArray.includes(updatedCode)) {
                    tempArray.push(updatedCode);
                }
                if (tempArray.length === maximumPostCodes) {
                    break
                }
            }
            numberToAdd = (numberToAdd + 1) % 10;
        }
        return tempArray;
    }

    function change(postCode: string, index: number): string {
        let result = '';
        for (const char of postCode) {
            const number = parseInt(char, 10);
            if (!isNaN(number)) {
                const newNumber = (number + index) % 10;
                result += newNumber.toString();
            } else {
                result += char;
            }
        }
        return result;
    }

    function getBookAppointmentAPIParams() {
        const now = moment.utc();
        const today12 = moment.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        const today2359 = moment.utc().set({ hour: 23, minute: 59, second: 0, millisecond: 0 });

        const diffFrom12ToNow = now.diff(today12, 'minutes');
        const diffFromNowTo2359 = today2359.diff(now, 'minutes');

        const bookedPatientParams: BookedPatientParameters = {
            MinutesBefore: diffFrom12ToNow > 300 ? 300 : diffFrom12ToNow,
            MinutesWithin: diffFromNowTo2359,
        }
        return bookedPatientParams
    }

    function sortSlotsBasedOnTime(entities: AppointmentUIEntity[]) {
        const sortedSlots = [...entities].sort((a, b) => {
            if (a.appointmentTime === undefined) return 1;
            if (b.appointmentTime === undefined) return -1;
            return a.appointmentTime.localeCompare(b.appointmentTime)
        });
        return sortedSlots
    }

    function getDemographicDetailTitle(maskDemographicDetails: boolean, detail?: string): string {
        if (detail) {
            return maskDemographicDetails
                ? `${detail}${Strings.CheckIn.PatientInfo.endingWith}`
                : detail;
        } else {
            return ''
        }
    }

    function getDemographicDetailValue(maskDemographicDetails: boolean, detail?: string, unmaskedCharacters?: number): string {
        if (detail && isNonEmpty(detail)) {
            return maskDemographicDetails ? maskInfo(detail, 'back', unmaskedCharacters ?? 3) : detail
        } else {
            return Strings.CheckIn.PatientInfo.NoInformationOnRecord
        }
    }

    function getDemoGraphicsDetail(maskDemographicDetails: boolean, patientInfo: Patient | undefined, detail: KioskDemographicDetailsType) {
        if (patientInfo) {
            switch (detail.demographicsDetailTypeId) {
                case 1:
                    return { title: detail.name, value: removeLastCharacter(CheckInViewModel().formatPatientName(patientInfo)) }
                case 3:
                    return { title: getDemographicDetailTitle(maskDemographicDetails, Strings.CheckIn.PatientInfo.postCode), value: getDemographicDetailValue(maskDemographicDetails, patientInfo.address?.postalCode) }
                case 4:
                    return { title: getDemographicDetailTitle(maskDemographicDetails, Strings.CheckIn.PatientInfo.email), value: getDemographicDetailValue(maskDemographicDetails, patientInfo.emailAddress) }
                case 5:
                    return { title: getDemographicDetailTitle(maskDemographicDetails, Strings.CheckIn.PatientInfo.mobileNumber), value: getDemographicDetailValue(maskDemographicDetails, patientInfo.mobile, 4) }
                case 6:
                    return { title: getDemographicDetailTitle(maskDemographicDetails, Strings.CheckIn.PatientInfo.homeTelephoneNumber), value: getDemographicDetailValue(maskDemographicDetails, patientInfo.homeTelephone, 4) }
            }
        } else {
            return undefined
        }
    }

    async function getDivertedSessionHolderIDs(): Promise<string[]> {
        try {
            const result = await commonAPIRepository.getMembers()
            const parsedResult: GetMemberResult = JSON.parse(result)
            const divertedSessionHolderIds =
                (parsedResult.data?.filter(data => data.attributes?.isDivertSet === true) ?? [])
                    .map(data => data.attributes?.sessionHolderId)
                    .filter((sessionHolderId): sessionHolderId is number => sessionHolderId !== undefined)
                    .map(item => item.toString())
            return divertedSessionHolderIds
        } catch (error) {
            console.log(error)
            return []
        }
    }

    function isDivertedSessionHolder(sessionHolderId: string, divertedSessionHolderIds: string[]) {
        if (sessionHolderId) {
            return divertedSessionHolderIds.includes(sessionHolderId)
        }
        return false
    }

    return {
        getTextForCheckInComponents,
        getGenderText,
        getValueForCheckInComponents,
        initiatePatientFindAPI,
        initiateBookedPatientsAPI,
        initiateCheckInAPI,
        formatPatientName,
        mapToAppointmentUIEntity,
        getUniqueNonEmptyPostCodesFromPatients,
        addRandomPostCodesIfNeeded,
        getBookAppointmentAPIParams,
        sortSlotsBasedOnTime,
        getDemoGraphicsDetail,
        getSessionHolderName,
        getSessionHolderWaitingName,
        isEarlyArrival,
        getDemographicDetailTitle,
        getDemographicDetailValue,
        mapToPatientFindParameters,
        change,
        getDivertedSessionHolderIDs,
        isDivertedSessionHolder,
    }
}